import { IonList, IonLabel, IonInput, IonItem, IonModal, IonAlert, IonActionSheet, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonSearchbar, IonTitle, IonToast, IonToolbar, IonButton, IonSegment, IonSegmentButton, IonCheckbox, IonImg } from '@ionic/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { helpCircle, closeOutline, addCircle, closeCircle, arrowBackOutline, share, informationCircle, location, briefcase, caretDownCircle, close, chevronBackCircle, personAdd, checkmarkDone, chevronForwardCircle, search, trash, starHalf, create, chatbubbleEllipses, fileTrayFull, filter, filterSharp, mail, person, business, arrowBack, chevronBackOutline, closeSharp, pencil, documentLock, options, alertCircle, flag } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import ContactAPI from '../apis/ContactAPI';
import AvailabilityApi from '../apis/Availability';
import ContactsHero from '../images/contacts-hero.png';
import { refreshOnboardingGuide } from '../state/actions/OnboardingGuide';
import store from '../state/store';
import { Contact, ContactSearchType, ContactType } from '../types/Contact';
import Utilities from '../Utilities';
import ContactListItem from './Components/Contacts/ContactListItem';
import OnboardingGuide from './Components/OnboardingGuide';
import InternalTracker from '../InternalTracker';
import Configuration from '../Configuration';
import { Plugins } from '@capacitor/core';
import ProfileProgress from './Components/ProfileProgress';
import { ChatService } from '../services/ChatService';
import RatingModal from './Components/Rating/RatingModal';
import { getDummyRatingRating } from '../types/Offer';
import UserAPI from '../apis/User';
import { Share } from '@capacitor/share';
import { Keyboard } from '@capacitor/keyboard';
import { Clipboard } from '@capacitor/clipboard';
import HandshakeImg from '../icons/handshake-solid.svg';
import CalendarImg from '../icons/calendar-check-solid.svg';
import InlineSelect from './Components/InlineSelect';
import ShareAndAgencyImg from '../icons/share-agency-min.png';
import ShareImg from '../icons/share-only-min.png';
import AgencyImg from '../icons/agency-only-min.png';
import AgencyStandaloneImg from '../icons/agency-standalone-min.png';
import ShareStandaloneImg from '../icons/share-standalone-min.png';
import ContactAddOptionsImg from '../images/contact-add-options.png';
import ReportModal, { ReportTypeId } from './Components/ReportModal';
import { borderRadius } from 'react-select/src/theme';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  contactsP?: Event[],
  modal? : "contact" | "agency",
  closeModal?: () => void
}

interface InviteParams {
  contactId?: string, 
  email?: string, 
  name?: string,
  firstName?: string, 
  lastName?: string,
  contacts?: ContactType[], 
  confirmRisky?: boolean,
  thirdPartyId?: string, 
  thirdPartyReference?: string,
  orgId?: string,
  orgName?: string,
  orgIdTemp?: string
}

interface ShareUpdate {
  action: 'add' | 'remove',
  userId: string
}

interface RateModalProps {
  userId?: string;
  uContactId?: string;
  contactId?: string;
  organisationId?: string;
  organisationType?: string;
  organisationName?: string;
  organisationNameJustStr?: string;
  userName?: string;
}

interface NewContactDetails {
  previousState?: NewContactDetails;
  contactName?: string,  
  firstName?: string,
  lastName?: string,
  contactEmail?: string,
  organisationName? : string,
  type: "hirer" | "agency" | "both" | "select"
  userId? : string,
  contactId? : string,
  organisationId? : string,
  forceShareOnly?: boolean,
  filledOrganisationFromSuggestions?: boolean,
  confirmedRisky?: boolean,
  hideTypeOptions?: string[],
}

let globalContactSearchText = "";
let organisationSuggestionsTimeout: any = null;


const Contacts: React.FC<Props> = ({ contactsP, modal, closeModal }) => {
  
  const [optionsContactType, setOptionsContactType] = useState <string> (null) // Id of the user whose options were opened
  const [optionsUserId, setOptionsUserId] = useState <string> (null) // Id of the user whose options were opened
  const [optionsOrgId, setOptionsOrgId] = useState <string> (null) // Id of the org whose options were opened
  const [optionsOrgName, setOptionsOrgName] = useState <string> (null) // name of the org whose options were opened
  const [optionsOrgTempId, setOptionsOrgTempId] = useState <number> (null) // Id of the temp org
  const [optionsRepresentingAgency, setOptionsRepresentingAgency] = useState <boolean> (null) // Whether the user is representing an agency
  const [optionsMainContactName, setOptionsMainContactName] = useState <string> (null) // Name of the main contact of the org whose options were opened - for temp agency editing
  const [optionsMainContactEmail, setOptionsMainContactEmail] = useState <string> (null) // Email of the main contact of the org whose options were opened - for temp agency editing
  const [optionsMainContactId, setOptionsMainContactId] = useState <string> (null) // Id of the main contact of the org whose options were opened - for temp agency editing
  const [optionsOrgTypeTemp, setOptionsOrgTypeTemp] = useState <NewContactDetails['type']> (null) // type of the org whose options were opened - for temp agency editing
  const [optionsOrgType, setOptionsOrgType] = useState <string> (null) // type of the org whose options were opened
  const [optionsConactId, setOptionsConactId] = useState <string> (null) // ContactId of the user whose options were opened
  const [optionsUserName, setOptionsUserName] = useState <string> (null) // Name of the user whose options were opened
  const [optionsCanRemoveContact, setOptionsCanRemoveContact] = useState <boolean> (null) // Whether the user can remove the contact
  const [optionsEmail, setOptionsEmail] = useState <string> (null) // Email of the user whose options were opened if bounced
  const [optionsContactId, setOptionsContactId] = useState <string> (null) // Id of the user contact whose options were opened
  const [agencies, setAgencies] = useState <ContactType[]> (null) // List of agencies
  const [hirers, setHirers] = useState <ContactType[]> (null) // List of hirers
  const [independent, setIndependent] = useState <ContactType[]> (null) // List of independent contacts
  const [outgoings, setOutgoings] = useState <ContactType[]> (null) // List of outgoing requests
  const [blockingLoading, setBlockingLoading] = useState <string | null> (null);
  const [firstOpen, setFirstOpen] = useState <boolean> (true) // Whether to show guide on first time open since app launch
  const [nonBlockingLoading, setNonBlockingLoading] = useState <string> (null) // Full screen loading
  const [section, setSection] = useState <string> ("list") // List / search view
  const [searchText, setSearchText] = useState <string> ("") // Search keywords
  const [searchResults, setSearchResults] = useState <ContactSearchType[]> ([]) // Search keywords
  const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
  const [errorToast, setErrorToast] = useState <string> (null) // Error toast message
  const [showJustAgencies, setShowJustAgencies] = useState <boolean> (false) // Whether to only show show contacts
  const [optionsContactOrgTempId, setOptionsContactOrgTempId] = useState <string> (null) // Id of the temp org
  const [emailEditingAlert, setEmailEditingAlert] = useState <boolean> (null) // Email editing dialog
  const [contactSelection, setContactSelection] = useState <boolean> (null) // Whether the user is selecting users
  const [selectedContacts, setSelectedContacts] = useState <string[]> ([]) // Selected contacts
  const [moreAccurateSearchTitle, setMoreAccurateSearchTitle] = useState <string> ("Want more accurate results?") // Title of more accurate dialog
  const [moreAccurateSearchSectors, setMoreAccurateSearchSectors] = useState <string> ("Enter your sectors & subsectors") // Sectors text of more accurate dialog
  const [moreAccurateSearchLocation, setMoreAccurateSearchLocation] = useState <string> ("Enter your postcode") // Locations text of more accurate dialog
  const [moreAccurateSearchSectorsSet, setMoreAccurateSearchSectorsSet] = useState <boolean> (false) // Whether the user has set sectors
  const [moreAccurateSearchLocationSet, setMoreAccurateSearchLocationSet] = useState <boolean> (false) // Whether the user has set location
  const [userGuideOpenSection, setUserGuideOpenSection] = useState <string> (null) // Opened profile guide section
  const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false) // Whether the keyboard is showing
  const [inviteMode, setInviteMode] = useState <string> (null) // What we require form the user
  const [inviteModalEmail, setInviteModalEmail] = useState <string> ("") // Invite modal email
  const [inviteModalEmailFocusAtBeginning, setInviteModalEmailFocusAtBeginning] = useState <boolean> (false) // Invite modal email
  const [inviteModalName, setInviteModalName] = useState <string> ("") // Invite modal Name
  const [inviteParams, setInviteParams] = useState <InviteParams> (null) // Invite Params
  const [confirmDifferentOrgContactAddOrgName, setConfirmDifferentOrgContactAddOrgName] = useState <string> ("")
  const [confirmOrgContactRejectedAddAsIndividual, setConfirmOrgContactRejectedAddAsIndividual] = useState <boolean> (false);
  const [rateModal, setRateModal] = useState <RateModalProps> (null);
  const [sendEmailButtonStatus, setSendEmailButtonStatus] = useState <string | null> (null) // Override to say email sent
  const [localSearchText, setLocalSearchText] = useState <string> ("") // Local contact search query
  const [invitingContactId, setInvitingContactId] = useState <string> (null);
  const [externalShareSelection, setExternalShareSelection] = useState <boolean> (null);
  const [sharingFilesSelection, setSharingFilesSelection] = useState <boolean> (null);
  const [sharingFilesType, setSharingFilesType] = useState <'file' | 'folder'> (null);
  const [sharedFileName, setSharedFileName] = useState <string> (null);
  const [sharedFileId, setSharedFileId] = useState <number> (null);
  const [sharingWithUserIds, setSharingWithUserIds] = useState <string[]> (null);
  const [shareUpdates, setShareUpdates] = useState <ShareUpdate[]> (null);
  const [imagesFailedToLoad, setImagesFailedToLoad] = useState <string[]> ([]);
  const [confirmContactRemove, setConfirmContactRemove] = useState <string[] | null> (null);
  const [newContactModal, setNewContactModal] = useState <NewContactDetails | null>(null);
  const [organisationSuggestions, setOrganisationSuggestions] = useState <{ id: string, name: string }[]> ([]);
  const [searchTextIsEmail, setSearchTextIsEmail] = useState <boolean> (false);
  const [helpSection, setHelpSection] = useState <string> (null);
  const [verificationRejectedByContactId, setVerificationRejectedByContactId] = useState <string> (null);
  const [reportConfirmContactId, setReportConfirmContactId] = useState <string> (null);
  const [orgContactSuggestions, setOrgContactSuggestions] = useState <{ id: string, firstName: string, lastName: string, contactId: string }[]> ([]);

  const contentRef = useRef(null);
  const history = useHistory();

  useEffect( () => {
    reloadUI()
    history.listen(e => {
      if (e.pathname.startsWith("/contacts")) {
        reloadUI()
      }
    })
    if (modal) {
      setSection("search");
    }
  }, [])

  useEffect(() => {
    if (modal && section === "search") {
      setTimeout(() => {
        // @ts-ignore
        const searchbarEl = document.querySelectorAll("#contacts-wrapper ion-searchbar") as HTMLIonSearchbarElement[];
        if (searchbarEl && searchbarEl[1]) {
          searchbarEl[1].setFocus();
        }
      }, 300)
    }
  }, [section])

  useEffect( () => {
    if (newContactModal) {
      setTimeout(() => {
        const firstInput = document.getElementById("organisations-name-input") as HTMLIonInputElement;
        const secondInput = document.getElementById("contacts-name-input") as HTMLIonInputElement;
        const anyInputHasFocus = firstInput && firstInput.value !== "" || secondInput && secondInput.value !== "";
        if (!anyInputHasFocus) {
          if (firstInput && firstInput.value === "") {
            firstInput.setFocus();
          } else if (secondInput && secondInput.value === "") {
            secondInput.setFocus();
          }
        }

        if (newContactModal.organisationId) {
          searchContactsInOrg(newContactModal.organisationId, "", "");
        }
      }, 500)
    } else {
      setOrgContactSuggestions([]);
    }
  }, [newContactModal])

  const reloadUI = async function() {
    if ((window as any).os !== "web") {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setTimeout(() => {
          setKeyboardOpen(true)
        })
      });
  
      Keyboard.addListener('keyboardWillHide', () => {
        setTimeout(() => {
          setKeyboardOpen(false)
        })
      });
    }

    reloadContacts();
    reloadUserSettings();
    openQueriedContact();
    setShowJustAgencies(window.location.pathname === "/agency");
    setSection(window.location.pathname === "/agency" || window.location.pathname === "/contacts/add" ? 'search' : 'list');
    const sharingType = 
      (window.location.pathname.startsWith("/sharefile") || window.location.pathname.startsWith("/sharefolder")) ?
      (window.location.pathname.startsWith("/sharefile") ? "file" : "folder") :
        null
    setSharingFilesSelection(sharingType ? true : false);
    setSharingFilesType(sharingType)
    setShareUpdates([]);

    if (window.location.href.indexOf('promptAdd=true') !== -1) {
      setSection("search");
      setTimeout(() => {
        const searchbarEl = document.querySelector("ion-searchbar") as HTMLIonSearchbarElement;
        console.log(searchbarEl)
        if (searchbarEl) {
          searchbarEl.setFocus();
        } else {
          setTimeout(() => {
            const searchbarEl = document.querySelector("ion-searchbar") as HTMLIonSearchbarElement;
            if (searchbarEl) {
              searchbarEl.setFocus();
            }
          }, 500)
        }
      })
      // clear promptAdd from the address bar, so doesn't get stuck when navigated away and back
      window.history.replaceState({}, document.title, window.location.href.replace("?promptAdd=true", ""));
    }

    if (window.location.href.indexOf('scrollTo=') !== -1) {
      scrollToSection(window.location.href.split('scrollTo=')[1]);
    }

    if (sharingType) {
      const contacts = (localStorage.getItem("contacts")) ? JSON.parse(localStorage.getItem("contacts") || "") : null;
      const user = (localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user") || "") : null;
      const files = user ? user.files : [];
      const folders = user ? user.folders : [];
      const fileId = parseInt(window.location.pathname.split("/").pop());
      setSharedFileName(sharingType === "file" ? (files.find(file => file.id === fileId)?.fileName || "") : (folders.find(folder => folder.id === fileId)?.name || ""));
      setSharedFileId(sharingType === "file" ? (files.find(file => file.id === fileId)?.id || "") : (folders.find(folder => folder.id === fileId)?.id || ""));
      setSharingWithUserIds(
        sharingType === "file" ? 
        (contacts?.sharedFiles?.filter(fs => fs.fileId === fileId).map(fs => fs.userId) || []) :
        (contacts?.sharedFolders?.filter(fs => fs.folderId === fileId).map(fs => fs.userId) || [])
      );
      setSearchText("");
      setSearchResults([]);
    } else {
      setSharingWithUserIds([])
    }
  }

  useEffect( () => {
    globalContactSearchText = searchText;
  }, [searchText])

  const openQueriedContact = async function() {
    const urlParts = window.location.href.split("/");
    let slug = urlParts[urlParts.length-1];
    const rate = slug.endsWith("#rate");
    slug = slug.replace("#rate", "");
    if (slug !== "contacts" && slug !== "add" && slug !== "agency" && window.location.href.indexOf("sharefile") === -1 && window.location.href.indexOf("sharefolder") === -1) {
      const el = document.querySelector(".contact-item[data-contact-id='" + slug + "']") as HTMLElement;
      if (el) {
        if (rate) {
          setRateModal({
            contactId: el.getAttribute("data-contact-id"),
            organisationId: null,
            organisationType: "independent",
            organisationName: null,
            userName: el.getAttribute("data-contact-name"),
            uContactId: el.getAttribute("data-contact-id"),
            organisationNameJustStr: el.getAttribute("data-org-name"),
          })
        }
        el.click();
      } else {
        setTimeout(() => {
          openQueriedContact();
        }, 300)
      }
    }
  }

  const shareAvailabilityExternally = async function() {
    setBlockingLoading("Getting Availability")
    const res = await AvailabilityApi.getFlattened();
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }

    InternalTracker.trackEvent("Availability Shared Externally");
    
    if (res && res.token && res.results) {

      const flattened = res.results;
      let startDate;
      let endDate;
      let days = [];
      let availabilityDOMText = "";

      for (const dayKey in flattened) {
        const day = flattened[dayKey];
        let currentSlot = [];

        if (!startDate) {
          startDate = day[0].start
        }

        for (let i = 0; i < day.length; i++) {
          let currentSlot = day[i];
          let nextSlot = day[i+1];
          if (currentSlot && nextSlot) {
            if (new Date(currentSlot.end).getMinutes() !== 0) {
              let newEndDate = new Date(currentSlot.end);
              newEndDate.setMinutes(0);
              day[i].end = newEndDate.toISOString();

              let newStartDate = new Date(nextSlot.start);
              newStartDate.setMinutes(0);
              day[i+1].start = newStartDate.toISOString();
            }
          }
        }

        let hours = [];

        for (let i = 0; i < day.length; i++) {
          const slot = day[i];

          let hourNow = new Date(slot.start).getHours();
          let dateNow = new Date(slot.start);
          let hourLast = new Date(slot.end).getHours();
          let dateLast = new Date(slot.end)
          while (dateNow < dateLast && hourNow < 24) {
            currentSlot.push(slot.available ? '🟩' : '🟥');
            hours.push(hourNow);
            hourNow++;
            dateNow = Utilities.dateAdd(dateNow, "hour", 1);
          }
        }

        days.push({
          date: new Date(dayKey),
          formattedDay: Utilities.formatDate(new Date(dayKey), "ds"),
          slots: currentSlot,
          hours: hours
        });

        if (availabilityDOMText === "") {
          availabilityDOMText += "🕞🕞  ";
        }

        const dateLetter = "   " + Utilities.formatDate(new Date(dayKey), "dss") + "  ";
        availabilityDOMText += dateLetter;
        // availabilityDOMText += (dateLetter === "M" ? "🅼" : dateLetter === "T" ? "🆃" : dateLetter === "W" ? "🆆" : dateLetter === "F" ? "🅵" : "🆂") + "\t"
      }

      for (let hourI = 0; hourI < days[0].hours.length; hourI++) {
        for (let dayI = 0; dayI < days.length; dayI++) {
          let hourAvailabilityStr = days[dayI].slots[hourI];
          let hourStr = days[dayI].hours[hourI];
          if (days[dayI].hours[hourI] > 5 && days[dayI].hours[hourI] < 18) {
            if (dayI === 0) {
              availabilityDOMText += "\n" + Utilities.toEmoji(((hourStr < 10) ? ("0" + hourStr) : hourStr)) + " "
            }
            availabilityDOMText += "\t" + hourAvailabilityStr;
          }
        }
      }

      const text = `
Here is my availability for the next 7 days, starting from ${Utilities.formatDate(startDate, "d mms, YYYY")}

${availabilityDOMText}

🟥 - Unavailable
🟩 - Available
Click this link for more details: `

      setBlockingLoading(null)

      if ((window as any).os === "web") {

        Clipboard.write({
            string: text + "\n" + CONFIG.PORTAL_URL + "/external/contact/" + user.contactId + "?token=" + res.token
        });

        (window as any).toast("Availability copied, you can paste it to anywhere", "info")

      } else {
      
        Share.share({
          title: `Here is my availability for the next 7 days, starting from ${Utilities.formatDate(startDate, "d mms, YYYY")}`,
          text: text,
          url: CONFIG.PORTAL_URL + "/external/contact/" + user.contactId + "?token=" + res.token,
          // dialogTitle: text
        }).catch(e => {});

      }
    } else {
      (window as any).toast("Failed to share", "error");
      setBlockingLoading(null)
    }

  }

  const reloadUserSettings = async function() {
    let user: any = localStorage.getItem("user");
    if (user) {

      user = JSON.parse(user);
      
      if (user.maxDistance !== null && user.maxDistance !== undefined && user.postalCode !== null && user.postalCode !== undefined && user.postalCode !== "") {
        setMoreAccurateSearchLocation((user.maxDistance ? (user.postalCode + " (" + user.maxDistance + " miles)") : (user.postalCode + " (any distance)")));
        setMoreAccurateSearchLocationSet(true);
      } else {
        setMoreAccurateSearchLocation("Enter your postcode");
        setMoreAccurateSearchLocationSet(false);
      }

      const sectorNames = [...new Set((user.workerAttributes ? user.workerAttributes : []).map(skill => skill.sectorName))]
      if (sectorNames && sectorNames.length !== 0) {
        setMoreAccurateSearchSectors((sectorNames.length + " Sector" + (sectorNames.length > 1 ? "s" : "") + " (" + sectorNames[0] + "" + (sectorNames.length > 1 ? (",..") : "") + ")"));
        setMoreAccurateSearchSectorsSet(true);
      } else {
        setMoreAccurateSearchSectors("Enter Skills/Sectors");
        setMoreAccurateSearchSectorsSet(false);
      }

      if (
        (user.maxDistance !== null && user.maxDistance !== undefined && user.postalCode !== null && user.postalCode !== undefined && user.postalCode !== "") &&
        (sectorNames && sectorNames.length !== 0)
      ) {
        setMoreAccurateSearchTitle(null);
      }

    } else {
      setMoreAccurateSearchTitle("Want more accurate results?");
    }
  }

  const loadCachedContacts = async function() {
    const contacts = (localStorage.getItem("contacts")) ? JSON.parse(localStorage.getItem("contacts") || "") : null;
    setHirers(contacts?.hirers || []);
    setAgencies(contacts?.agencies || []);
    setOutgoings(contacts?.outgoing || []);
    setIndependent(contacts?.others || []);
  }

  const reloadContacts = async function(success?: string, error?: string) {

    loadCachedContacts();
    setNonBlockingLoading("Fetching contacts");
    setLocalSearchText("");

    Utilities
      .getContacts()
      .then(data => {
        setNonBlockingLoading(null);
        // @ts-ignore
        setOutgoings(data.outgoings);
        // @ts-ignore
        setHirers(data.hirers);
        // @ts-ignore
        setAgencies(data.agencies);
        // @ts-ignore
        setIndependent(data.others);
        store.dispatch(refreshOnboardingGuide());
      })
      .catch(error => {
        setErrorToast("Failed to load contacts");
        setNonBlockingLoading(null)
      })

  }

  const searchContacts = async function(keywords: string, showJustAgencies?: boolean) {

    if (keywords) {

      keywords = keywords.trim();

      setFirstOpen(false);
      setNonBlockingLoading("Searching Online");

      if (keywords) {
        InternalTracker.trackEvent("Contacts Searched", {
          text: keywords.trim(),
          type: "prospective"
        })
      }

      Promise.all([
        ContactAPI.searchClearbit(keywords),
        ContactAPI.search(keywords, showJustAgencies),
        ContactAPI.getAgencyContactInformation(keywords)
      ]).then(allData => {
        const clearBitData = allData[0];
        // @ts-ignore
        let data = allData[1].data.results;
        const contactInfo = allData[2];
        
        if (keywords.trim() !== globalContactSearchText.trim()) {
          return;
        }

        let currentUserEmail = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).email : "";

        if (showJustAgencies) {
          data = data.filter(res => res.isAgency);
          if (data.length === 0) data.push({ isAgency: false, isHirer: false, matches: [] });
        }

        if (data[0] && data[0].matches.length === 0 && (!data[1] || (data[1] && data[1].length === 0) ) && (!data[2] || (data[2] && data[2].length === 0) ) && (!data[3] || (data[3] && data[3].length === 0) ) ) {
          data.splice(0, 1)
          // Inviting by email
          if (Utilities.isEmail(keywords) && keywords !== currentUserEmail) {
            // data.push({
            //   "matches": [
            //       {
            //           "contactId": "",
            //           "userId": "",
            //           "email": keywords,
            //           "firstName": "Send",
            //           "lastName": "Invitation Email"
            //       },
            //     ]
            // })
          }
        }

        for (let i = 1; i < data.length; i++) {
          let element = data[i];
          if (element.matches.length === 0) {
            data[i].matches.push(
              {
                "contactId": "",
                "userId": "",
                "email": "-",
                "firstName": "-",
                "lastName": "-"
              },
            )
          }
        }

        data.unshift({
          "matches": [
              {
                  "contactId": "",
                  "userId": "",
                  "email": keywords,
                  "firstName": "Add '" + (keywords ? Utilities.capitalizeFirstLetter(keywords) : keywords) + "' manually",
                  "lastName": ""
              },
            ]
        })

        if (contactInfo && contactInfo.length > 0) {
          for (let i = 0; i < contactInfo.length; i++) {
            const signedUpMatchIndex = data.findIndex(org => org.organisationName && org.organisationName.toLowerCase() === contactInfo[i].org.toLowerCase());
            const clearBitMatchIndex = clearBitData.findIndex(org => org.name.toLowerCase() === contactInfo[i].org.toLowerCase());

            if (signedUpMatchIndex > -1) {
              continue;
            }

            if (clearBitMatchIndex > -1) {
              data.push({
                matches: [
                  {
                    "contactId": "",
                    "userId": "",
                    "email": contactInfo[i].email,
                    "firstName": contactInfo[i].name,
                    "lastName": ""
                  }
                ],
                organisationName: clearBitData[clearBitMatchIndex].name,
                organisationImageUrl: "https://logo.clearbit.com/" + contactInfo[i].domain,
                knownUnsignedUpAgency: true,
                // organisationImageUrl: clearBitData[clearBitMatchIndex].logo,
                organisationId: "",
                isAgency: true,
                isHirer: false,
              })
              clearBitData.splice(clearBitMatchIndex, 1);
              continue;
            }

            data.push({
              matches: [
                {
                  "contactId": "",
                  "userId": "",
                  "email": contactInfo[i].email,
                  "firstName": contactInfo[i].name,
                  "lastName": ""
                }
              ],
              organisationName: contactInfo[i].org,
              organisationImageUrl: "https://logo.clearbit.com/" + contactInfo[i].domain,
              knownUnsignedUpAgency: true,
              organisationId: "",
              isAgency: true,
              isHirer: false,
            })
          }
        }

        if (clearBitData && clearBitData.length > 0) {
          for (let i = 0; i < clearBitData.length; i++) {
            data.push({
              matches: [],
              organisationName: clearBitData[i].name,
              organisationImageUrl: clearBitData[i].logo,
              emailDomain: "",
              email: "",
              verified: false,
              thirdParyReference: "clearbit",
              organisationId: "",
              isNewOrganisation: true,
              isHirer: false,
              isAgency: false,
            })
          }
        }

        setSearchResults(data);
        setNonBlockingLoading(null);

      }).catch(e => {
        console.log(e);
        setErrorToast("Failed to search contacts");
        setNonBlockingLoading(null)
      })

    } else {
      setSearchResults([])
    }

  }

  const removeContact = async function(contactId, orgTempId?) {

    setNonBlockingLoading("Deleting Contact");

    if (orgTempId) {

      InternalTracker.trackEvent("Contact Deleted", {
        type: "agency",
        id: orgTempId
      })

      ContactAPI.removeTempOrg(orgTempId).then(() => {
        setSuccessToast("Agency remove");
        reloadContacts();
        ChatService.reloadContacts();
        setNonBlockingLoading(null)
      }).catch(e => {
        setErrorToast("Failed to remove agency");
        reloadContacts();
        setNonBlockingLoading(null)
      })

    } else {

      InternalTracker.trackEvent("Contact Deleted", {
        type: "contact",
        id: contactId
      })

      ContactAPI.remove(contactId).then(() => {
        setSuccessToast("Contact deleted");
        reloadContacts();
        setNonBlockingLoading(null)
        ChatService.reloadContacts();
      }).catch(e => {
        setErrorToast("Failed to delete contact");
        reloadContacts();
        setNonBlockingLoading(null)
      })

    }

  }

  const toggleSelectedContact = function(contactId: string, selectedContactIds: string[]) {
    let index = selectedContactIds.indexOf(contactId);
    if (index === -1) {
      selectedContactIds.push(contactId);
    } else {
      selectedContactIds.splice(index, 1);
    }
    setSelectedContacts(JSON.parse(JSON.stringify(selectedContactIds)));
  }

  const sendAvailabilityEmail = function(selectedContactIds: string[]) {
    setNonBlockingLoading("Sending email");
    let user = JSON.parse(localStorage.getItem("user"));
    
    AvailabilityApi.send(selectedContactIds, user.id).then(d => {
      setSendEmailButtonStatus("Email sent to " + selectedContactIds.length + " contacts");
      setSuccessToast("Emails Sent");
      setNonBlockingLoading(null);

      setTimeout(() => {
        setSendEmailButtonStatus(null)
        setContactSelection(false);
      }, 1200)

      InternalTracker.trackEvent("Availability Shared Email", {
        userIds: selectedContactIds.length
      })
    }).catch(e => {
      setErrorToast("We failed to send out the emails");
      setNonBlockingLoading(null)
      console.log(e)
    })
  }

  const handleInviteErr = function(e: any) {
    if (e && e.response && e.response.data && e.response.data.detail === "Duplicate resource exists with the same name or ID") {
      (window as any).toast("You have already added this contact", "error")
    } else {
      (window as any).toast("We failed to send the invitation, please try later", "error")
    }
    setNonBlockingLoading(null);
  }

  const selectReferral = function(contact, userType, orgName?) {
    if (localStorage.getItem("referral-state")) {
      contact.organisationName = orgName;
      localStorage.setItem("referral-contact", JSON.stringify(contact));
      history.push("/referrals/add");
    }
  }

  const setSendInviteMode = async function(
    contactId?: string, 
    email?: string, 
    firstName?: string, 
    lastName?: string,
    contacts?: ContactType[], 
    confirmRisky?: boolean,
    thirdPartyId?: string, 
    thirdPartyReference?: string,
    orgId?: string,
    orgName?: string,
    suggestedEmail?: string,
    orgIdTemp?: string,
    orgNameTemp?: string
  ) {

    setInviteParams({
      contactId: contactId, 
      email: email, 
      firstName: firstName, 
      lastName: lastName,
      thirdPartyId: thirdPartyId, 
      thirdPartyReference: thirdPartyReference,
      orgId: orgId,
      orgName: orgName,
      orgIdTemp: orgIdTemp
    })

    if (suggestedEmail) {
      setInviteModalEmail(suggestedEmail);
      setInviteModalEmailFocusAtBeginning(true);
    } else {
      setInviteModalEmailFocusAtBeginning(false);
    }
    
    console.log(orgIdTemp, thirdPartyId, thirdPartyReference, firstName)

    if (orgIdTemp) {

      InternalTracker.trackEvent("Contact Invited", {
        type: "agency",
        id: orgIdTemp
      })

      setBlockingLoading("Sending Invite");
      
      // Email is org name
      ContactAPI.addAgencyById(orgIdTemp).then(d => {
        setSuccessToast("Agency '" + orgNameTemp + "' has been added");
        setSearchText("");
        setFirstOpen(true);
        setSearchResults([]);
        setBlockingLoading(null);
      }).catch(e => {
        setErrorToast("We failed to add this agency, please try later");
        console.log(e);
        setBlockingLoading(null);
      })

    } else if ( (thirdPartyId && thirdPartyReference) || orgId ) {
      setInviteMode("thirdParty");
    } else if (firstName && firstName.endsWith("add their email address.")) {
      setInviteModalName(Utilities.capitalizeEachFirstLetter(email));
      setInviteMode("withEmail");
      setTimeout(() => {
        const el = document.querySelector("#invite-moda-email-input") as HTMLIonInputElement;
        if (el) { el.setFocus(); } else {
          setTimeout(() => {
            const el = document.querySelector("#invite-moda-email-input") as HTMLIonInputElement;
            if (el) { el.setFocus(); }
          }, 500);
        }
      }, 250);
    } else if (firstName && firstName.endsWith(" as agency.")) {

      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].organisationName.trim().toLowerCase() === email.trim().toLowerCase()) {
          setErrorToast("This agency is already on your list");
          return;
        }
      }

      InternalTracker.trackEvent("Contact Invited", {
        type: "agency",
        email: email.trim()
      })

      setBlockingLoading("Sending Invite");
      
      // Email is org name
      ContactAPI.addAgency(email.trim()).then(d => {
        setSuccessToast("Agency '" + email + "' has been added");
        setSearchText("");
        setFirstOpen(true);
        setSearchResults([]);
        ChatService.reloadContacts();
        setBlockingLoading(null);
      }).catch(e => {
        setErrorToast("We failed to add this agency, please try later");
        console.log(e);
        setBlockingLoading(null);
      })

    } else if (!contactId) {
      setInviteModalEmail(email);
      if (
        (firstName || lastName) && 
        !(firstName === "Send" && lastName === "Invitation Email") &&
        firstName.indexOf("not found?") === -1 &&
        !(firstName === "-" && lastName === "-")
      ) {
        setInviteModalName(((firstName || "") + " " + (lastName || "")).trim());
      }
      setInviteMode("withName")
    } else {

      if (nonBlockingLoading) {
        return;
      }

      InternalTracker.trackEvent("Contact Invited", {
        type: "contact",
        id: contactId
      })

      setNonBlockingLoading("Sending Invite");

      setInvitingContactId(contactId)

      // Inviting with contact id
      ContactAPI.invite(contactId).then(() => {
        setSuccessToast("Invitation sent");
        setNonBlockingLoading(null);
        setInvitingContactId(null)
      }).catch(e => {
        setInvitingContactId(null)
        handleInviteErr(e);
      })

    }

  }

  const searchContactsInOrg = async function(
    orgId: string, 
    firstName: string,
    lastName: string,
  ) {
    const query = ((firstName || "") + " " + (lastName || "")).trim();
    // if (!query) {
    //   setOrgContactSuggestions([]);
    //   return;
    // }
    const results = await UserAPI.searchUsersInOrganisation(orgId, query);
    setOrgContactSuggestions(results.data);
  }

  const sendExtendedInvite = async function(
    inviteParams: InviteParams,
    inviteMode: string,
    confirmRisky?: boolean,
    overrideMembership?: boolean
  ) {

    if (inviteParams.email) {
      const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
      if (user && user.email === inviteParams.email) {
        window.alert("You have entered your own email, please enter your contact's email, so we can send them an invite");
        return;
      }
    }

    if (nonBlockingLoading) {
      return;
    }

    setInviteParams(inviteParams)

    if (!inviteParams.name) {
      (window as any).toast("Please enter a name")
      return;
    }

    if (!inviteParams.email) {
      (window as any).toast("Please enter an email")
      return;
    }

    if (!Utilities.isEmail(inviteParams.email)) {
      (window as any).toast("Please enter a valid email")
      return;
    }

    setNonBlockingLoading("Sending Invite");

    if (inviteMode === "thirdParty") {

      InternalTracker.trackEvent("Contact Invited", {
        type: "thirdParty",
        id: inviteParams.thirdPartyId,
        email: inviteParams.email,
        name: inviteParams.name
      })

      ContactAPI.invite(undefined, inviteParams.email, inviteParams.name, confirmRisky, inviteParams.thirdPartyId, inviteParams.thirdPartyReference, inviteParams.orgId, overrideMembership).then(() => {
        setSuccessToast("Invitation sent");
        setSection("list");
        setSearchResults([]);
        setSearchText("")
        setFirstOpen(true);
        reloadContacts();
        setInviteMode(null);
        setInviteModalEmail("")
        setInviteModalName("")
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        if (e && e.response && e.response.data == "Invalid or risky email address submitted") {
          if (window.confirm(inviteParams.email + ": email address unknown - please confirm it is correct by tapping on OK, or cancel to make changes.")) {
            sendExtendedInvite(inviteParams, inviteMode, true)
          }
        } else if (e && e.response && e.response.data && e.response.data.detail.startsWith("Contact was not added as is already a confirmed member of")) {
          let orgName = e.response.data.detail.match(/member of .+?(?=\.)/);
          if (orgName && orgName[0]) orgName = orgName[0].replace("member of", "");
          if (orgName) {
            setConfirmDifferentOrgContactAddOrgName(orgName);
          }
        } else if (e && e.response && e.response.data && e.response.data.detail === "Duplicate resource exists with the same name or ID") {
          (window as any).toast("You have already added this contact", "error")
        } else if ((e && e.response && e.response.data && e.response.data.detail.startsWith("Contact was not added as they were previously rejected as a member of"))) {
          setConfirmOrgContactRejectedAddAsIndividual(true);
        } else {
          handleInviteErr(e)
        }
        setNonBlockingLoading(null);
      })

    } else {

      InternalTracker.trackEvent("Contact Invited", {
        type: "thirdParty",
        email: inviteParams.email,
        name: inviteParams.name
      })

      ContactAPI.invite(undefined, inviteParams.email, inviteParams.name, confirmRisky).then(() => {
        setSuccessToast("Invitation sent");
        setSection("list");
        setSearchResults([]);
        setSearchText("")
        setFirstOpen(true);
        reloadContacts();
        setInviteMode(null);
        setInviteModalEmail("")
        setInviteModalName("")
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        if (e && e.response && e.response.data == "Invalid or risky email address submitted") {
          if (window.confirm(inviteParams.email + ": email address unknown - please confirm it is correct by tapping on OK, or cancel to make changes.")) {
            sendExtendedInvite(inviteParams, inviteMode, true)
          }
        } else if (e && e.response && e.response.data && e.response.data.detail.startsWith("Contact was not added as is already a confirmed member of")) {
          let orgName = e.response.data.detail.match(/member of .+?(?=\.)/);
          if (orgName && orgName[0]) orgName = orgName[0].replace("member of", "");
          if (orgName) {
            setConfirmDifferentOrgContactAddOrgName(orgName);
          }
        } else if (e && e.response && e.response.data && e.response.data.detail === "Duplicate resource exists with the same name or ID") {
          (window as any).toast("You have already added this contact")
        } else if ((e && e.response && e.response.data && e.response.data.detail.startsWith("Contact was not added as they were previously rejected as a member of"))) {
          setConfirmOrgContactRejectedAddAsIndividual(true);
        } else {
          handleInviteErr(e)
        }
        setNonBlockingLoading(null);
      })

    }

  }

  const updateShare = function(fileId: number, updates: ShareUpdate[], type: 'file' | 'folder') {
    setBlockingLoading("Updating File Share")

    InternalTracker.trackEvent(Utilities.capitalizeFirstLetter(type) + " Share Updated", {
      fileId: fileId,
      updates: updates
    })

    Promise.all(updates.map(update => {
      if (update.action === "add") {
        if (type === 'folder') {
          return ContactAPI.shareFolder(fileId, update.userId);
        } else {
          return ContactAPI.shareFile(fileId, update.userId);
        }
      } else {
        if (type === 'folder') {
          return ContactAPI.removeFolderShare(fileId, update.userId);
        } else {
          return ContactAPI.removeFileShare(fileId, update.userId);
        }
      }
    })).then( async () => {
      await Utilities.getContacts();
      (window as any).toast("Shares updated", "success");
      setBlockingLoading(null);
      setTimeout(() => {
        window.location.href = "/"
      }, 1000)
    }).catch( async () => {
      await Utilities.getContacts();
      (window as any).toast("Failed to update file share", "error");
      setBlockingLoading(null);
      setTimeout(() => {
        window.location.href = "/"
      }, 1000)
    })
  }

  const contactSearchMatch = function(query: string, firstName: string, lastName: string): boolean {
    if (!firstName || !lastName || !query) {
      return false;
    }
    
    return (
      firstName.toLowerCase().indexOf(query) !== -1 ||
      lastName.toLowerCase().indexOf(query) !== -1 ||
      (firstName + " " + lastName).toLowerCase().indexOf(query) !== -1 ||
      (lastName + " " + firstName).toLowerCase().indexOf(query) !== -1
    )
  }

  const getOrganisationSuggestions = async function(query: string, force? : boolean) {
    if (!force) {
      clearTimeout(organisationSuggestionsTimeout);
      organisationSuggestionsTimeout = setTimeout(() => {
        getOrganisationSuggestions(query, true);
      }, 600);
      return;
    }
    if (!query) {
      setOrganisationSuggestions([]);
    } else {
      const res = await UserAPI.searchOrganisations(query);
      setOrganisationSuggestions(res);
    }
  }

  const toggleFileShare = async function(shareUpdates: ShareUpdate[], sharingWithUserIds: string[], userId: string) {
    let updates = shareUpdates;
    let sharingWith = sharingWithUserIds;
    let sharingLast = sharingWith.indexOf(userId) !== -1
    updates = updates.filter(update => update.userId !== userId);
    if (sharingLast) {
      sharingWith.splice( sharingWith.indexOf(userId), 1);
    }  else {
      sharingWith.push(userId)
    }
    updates.push({
      userId: userId,
      action: sharingLast ? 'remove' : 'add'
    })
    setShareUpdates(updates);
    setSharingWithUserIds(sharingWith)
  }

  const scrollToSection = function(section: string) {
    const el = document.querySelector('div[data-first="true"][data-parent-type="' + section + '"]');
    if (el) {
      el.scrollIntoView();
    } else {
      setTimeout(() => {
        scrollToSection(section);
      }, 250);
    }
  }

  const scrollToContactItem = function(type: "org-internal" | "org-external" | "contact" | "new" | "contact-external", id: string) {
    let el = null;
    if (type === "new") {
      el = document.getElementById("add-as-both-btn");
      console.log(el, "<<<,");
    } else if (type === "contact") {
      el = document.querySelector(".contact-item[data-contact-id='" + id + "']");
    } else if (type === "org-internal") {
      el = document.querySelector(".company-header-item[data-org-id='" + id + "']");
    } else if (type === "org-external") {
      el = document.querySelector(".company-header-item[data-org-name='" + id + "']");
    } else if (type === "contact-external") {
      el = document.querySelector(".contact-item[data-contact-name='" + id + "']");
    }
    if (!el) {
      setTimeout(() => {
        scrollToContactItem(type, id);
      }, 200);
      return;
    }
    if (type === "new") {
      el.click();
    } else {
      el.setAttribute("data-highlighted", "true");
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        el.setAttribute("data-highlighted", "false");
      }, 800)
    }
  }

  const sendInviteV2 = async function(details: NewContactDetails) {

    if (details.type === "select") {
      setErrorToast("Please select a contact type");
      return;
    }

    if (!details.contactId) {
      //if (details.type === "hirer" || details.type === "both") {
        if (!details.firstName || !details.lastName) { setErrorToast("Please enter a contact email"); return; }
        if (!details.firstName || !details.lastName) { setErrorToast("Please enter a contact name"); return; }
      //}
  
      //if (details.type === "agency" || details.type === "both") {
        if (!details.organisationName) { setErrorToast("Please enter an agency name"); return; }
      //}
    }

    if (details.contactEmail && !Utilities.isEmail(details.contactEmail)) {
      setErrorToast("Please enter a valid email address");
      return;
    }

    if (details.contactEmail) {
      details.contactEmail = details.contactEmail.trim();
    }
    if (details.firstName && details.lastName) {
      details.contactName = (details.firstName + " " + details.lastName).trim();
    }
    if (details.organisationName) {
      details.organisationName = details.organisationName.trim();
    }

    if (details.previousState) {
      setBlockingLoading("Updating Connection...")
      const hasChanged = (
        details.previousState.contactEmail !== details.contactEmail ||
        details.previousState.contactName !== details.contactName ||
        details.previousState.organisationName !== details.organisationName ||
        details.previousState.type !== details.type ||
        details.previousState.organisationId !== details.organisationId ||
        details.previousState.contactId !== details.contactId
      )

      if (hasChanged) {
        if (details.previousState.organisationId && Utilities.intOrNaN(details.previousState.organisationId)) {
          await ContactAPI.removeTempOrg(details.previousState.organisationId);
          if (details.previousState.organisationId === details.organisationId) {
            details.organisationId = undefined;
          }
        }
        if (details.previousState.contactId) {
          await ContactAPI.remove(details.previousState.contactId);
          if (details.previousState.contactId === details.contactId) {
            details.contactId = undefined;
          }
        }
      } else {
        setBlockingLoading(null);
        setNewContactModal(null);
        return;
      }
    } else {
      setBlockingLoading("Creating Connection...")
    }

    let error = false;
    let goBackToContacts = false;
    let highlightType = null;
    let highlightId = null;

    try {
      // Completely new user without org or user id
      if (!details.contactId && !details.organisationId) {
        // User not yet signed up
        if (details.type === "hirer" || details.type === "both") {
          await ContactAPI.invite(undefined, details.contactEmail, details.contactName, details.confirmedRisky, undefined, details.type !== "hirer" ? details.organisationName || undefined : null);
        }
        if (details.type === "agency" || details.type === "both") {
          await ContactAPI.addAgency(details.organisationName, details.contactEmail, details.contactName);
        }
        if (details.contactName) {
          highlightType = "contact-external";
          highlightId = details.contactName;
        } else {
          highlightType = "org-external";
          highlightId = details.organisationName;
        }
        goBackToContacts = true;
      } else if (details.contactId && details.organisationId) {
        if (details.type === "hirer" || details.type === "both") {
          await ContactAPI.invite(details.contactId, undefined, undefined, details.confirmedRisky, undefined, undefined, undefined, undefined, undefined);
        }
        if (details.type === "agency" || details.type === "both") {
          await ContactAPI.addAgencyById(details.organisationId);
        }
      } else if (details.organisationId) {
        if (details.type === "hirer" || details.type === "both") {
          await ContactAPI.invite(undefined, details.contactEmail, details.contactName, details.confirmedRisky, undefined, undefined, /*details.type !== "hirer" ?*/ details.organisationId /*: null*/, undefined, undefined);
        }
        if (details.type === "agency" || details.type === "both") {
          await ContactAPI.addAgencyById(details.organisationId);
        }
      } 
    } catch (e) {
      error = true;
      if (e && e.response && e.response.data == "Invalid or risky email address submitted") {
        if (window.confirm(details.contactEmail + " is an unknown email address - please confirm it is correct by tapping on Ok.")) {
          sendInviteV2({
            ...details,
            confirmedRisky: true
          });
        } else {
          setBlockingLoading(null);
        }
      } else if (e && e.response && e.response.data && e.response.data.title === "Duplicate Resource") {
        (window as any).toast("You have already added this contact", "error");
        setBlockingLoading(null);
      } else {
        (window as any).toast("Failed to send invite", "error");
        setBlockingLoading(null);
      }
    }

    if (!error) {
      InternalTracker.trackEvent("Contact Invited", details);
      setSuccessToast(details.type === "hirer" ? "Invitation Sent" : "Contact Added");
      reloadContacts();
      ChatService.reloadContacts();
      setNewContactModal(null);
      setBlockingLoading(null);
      if (goBackToContacts) {
        setTimeout(() => {
          setSection("list");
          scrollToContactItem(highlightType, highlightId);
        }, 1000)
      }

      if (modal && closeModal) {
        // todo show status of invite / just show on list
        closeModal();
      }
    }
  }

  const requestReVerification = async function(
    verifyingContactId?: string, 
    verifyingContactTempOrgId?: string,
  ) {
    ContactAPI.requestReVerification(verifyingContactId, verifyingContactTempOrgId).then(() => {
      setSuccessToast("Verification Requested");
      reloadContacts();
      setNonBlockingLoading(null);
    }).catch(e => {
      setErrorToast("Failed to request verification");
      setNonBlockingLoading(null);
    })
  }

  const sendInvite = async function(
    contactId?: string, 
    email?: string, 
    firstName?: string, 
    lastName?: string,
    contacts?: ContactType[], 
    confirmRisky?: boolean,
    thirdPartyId?: string, 
    thirdPartyReference?: string,
    orgId?: string,
  ) {

    if (nonBlockingLoading) {
      return;
    }

    setNonBlockingLoading("Sending Invite");

    if ( (thirdPartyId && thirdPartyReference) || orgId ) {

      email = email || window.prompt("Enter the email address of your contact?")

      if (!email || !email.trim()) {
        (window as any).toast("Please enter the email of your contact to send an invitation");
        setNonBlockingLoading(null);
        return;
      }

      let name = firstName || window.prompt("Enter the name of your contact?");

      if (!name || !name.trim()) {
        (window as any).toast("Please enter the name of your contact to send an invitation");
        setNonBlockingLoading(null);
        return;
      }

      ContactAPI.invite(undefined, email.trim(), name.trim(), confirmRisky, thirdPartyId, thirdPartyReference, orgId).then(() => {
        setSuccessToast("Invitation sent");
        setSection("list");
        setSearchResults([]);
        setSearchText("")
        setFirstOpen(true);
        reloadContacts();
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        if (e && e.response && e.response.data == "Invalid or risky email address submitted") {
          if (window.confirm(email + ": email address unknown - please confirm it is correct by tapping on OK, or cancel to make changes.")) {
            sendInvite(undefined, email.trim(), name.trim(), "", [], true, thirdPartyId, thirdPartyReference, orgId);
          }
        } else {
          handleInviteErr(e)
        }
        setNonBlockingLoading(null);
      })      

    } else if (firstName.endsWith("add their email address.")) {
      
      // Inviting with email address and contact name
      let name = email.trim();
      email = window.prompt("Enter the email address of your contact?")

      if (!email || !email.trim()) {
        (window as any).toast("Please enter the email of your contact to send an invitation");
        setNonBlockingLoading(null);
        return;
      }

      if (!Utilities.isEmail(email.trim())) {
        (window as any).toast("Invalid Email Address");
        setNonBlockingLoading(null);
        return;
      }

      InternalTracker.trackEvent("Contact Invited", {
        email: email.trim(),
      });

      ContactAPI.invite(undefined, email, name).then(() => {
        setSuccessToast("Invitation sent");
        setSection("list");
        setSearchResults([]);
        setSearchText("")
        setFirstOpen(true);
        reloadContacts();
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        handleInviteErr(e)
      })      

    } else if (firstName.endsWith(" as agency")) {

      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].organisationName.trim().toLowerCase() === email.trim().toLowerCase()) {
          setErrorToast("This agency is already on your list");
          setNonBlockingLoading(null);
          return;
        }
      }

      InternalTracker.trackEvent("Contact Invited", {
        email: email.trim(),
      });
      
      // Email is org name
      ContactAPI.addAgency(email.trim()).then(d => {
        setSuccessToast("Agency '" + email + "' has been added");
        setSearchText("");
        setFirstOpen(true);
        setSearchResults([]);
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        setErrorToast("We failed to add this agency, please try later");
        console.log(e)
        setNonBlockingLoading(null);
      })

    } else if (!contactId) {

      // Inviting with email address and contact name
      let name: string = confirmRisky ? firstName : window.prompt("Enter the name of your contact?");
      if (!name || !name.trim()) {
        (window as any).toast("Please enter the name of your contact to send an invitation")
        return;
      }

      InternalTracker.trackEvent("Contact Invited", {
        email: email.trim(),
        name: name.trim(),
      })

      ContactAPI.invite(undefined, email, name.trim(), confirmRisky).then(() => {
        setSuccessToast("Invitation sent");
        setSection("list");
        setSearchResults([]);
        setSearchText("")
        setFirstOpen(true);
        reloadContacts();
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        if (e && e.response && e.response.data == "Invalid or risky email address submitted") {
          if (window.confirm(email + ": email address unknown - please confirm it is correct by tapping on OK, or cancel to make changes.")) {
            sendInvite(contactId, email, name.trim(), lastName, contacts, true);
          }
        } else {
          handleInviteErr(e)
        }
        setNonBlockingLoading(null);
      })

    } else {

      InternalTracker.trackEvent("Contact Invited", {
        id: contactId, 
      })

      // Inviting with contact id
      ContactAPI.invite(contactId).then(() => {
        setSuccessToast("Invitation sent");
        setSection("list");
        setSearchResults([]);
        setSearchText("")
        setFirstOpen(true);
        reloadContacts();
        ChatService.reloadContacts();
        setNonBlockingLoading(null);
      }).catch(e => {
        handleInviteErr(e)
      })

    }

  }

  let localSearchTextFormatted = localSearchText.toLocaleLowerCase().trim();

  const SEARCHBAR_DOM = 
    <IonSearchbar 
    placeholder={"Add / Search for Contacts"} 
    debounce={600} 
    defaultValue={localSearchText}
    value={localSearchText}
    onKeyUp={e => {
      console.log("key up ", e);
      const text = (e.target as HTMLInputElement).value
      if (!sharingFilesSelection && !contactSelection && window.location.pathname !== "/selectreferral") {
        setSearchResults([]);
        setSearchText(text)
        setSearchTextIsEmail(Utilities.isEmail(text))
      }
      setLocalSearchText(text);
    }}
    onIonInput={(e) => {
      searchContacts(searchText, showJustAgencies);
    }}
  ></IonSearchbar>

  const SEARCHBAR_CLEAR_FILTERS_DOM = searchText ?
    <button 
      onClick={() => {
        if (modal) {
          setSearchResults([]);
          setSearchText("");
          setLocalSearchText("");
        } else {
          setSearchResults([]);
          setSearchText("");
          setLocalSearchText("");
          setSection("list");
        }
      }}
      className='clear-filters-btn'
    >
      <IonIcon icon={close} />
      <span>Clear Filters</span>
    </button>
  : null

  const MIN_CHARS_DOM = null
    // (searchText.length && searchText.length < 3) ?
    //   <div className="top-banner warning">
    //     <IonIcon icon={informationCircle} />
    //     <span>Enter at least 3 characters to search for new contacts</span>
    //   </div>
    // : null

  return (
    <IonPage data-page="contacts" ref={contentRef}>
      <IonHeader mode='md'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle style={{ 
            paddingInline: section === "search" ? 10 : undefined
          }}>
            { (section === "search") &&
              <div className='back-btn'>
                <IonIcon 
                  icon={modal ? closeOutline : chevronBackOutline} 
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    if (modal) {
                      if (closeModal) {
                        closeModal();
                      }
                    } else {
                      setSection("list");
                      setSearchResults([]);
                      setSearchText("");
                      setLocalSearchText("");
                      setFirstOpen(true);
                      history.replace("/contacts") // rewrite url to prevent this view opening when navigating back
                    }
                  }}
                />
              </div>
            }
            {sharingFilesSelection ? ("Share " + sharedFileName) : showJustAgencies ? "Add agencies" : window.location.pathname === "/selectreferral" ? "Tap on a contact to select" : section === "search" ? "" : "Contacts"}
          </IonTitle>
          { section === "list" && window.location.pathname !== "/selectreferral" &&
            <IonSegment mode="ios" value={'all'} onIonChange={e => { 
                const newValue = e.detail.value;
                InternalTracker.trackEvent("Contacts Filter", {
                  type: newValue,
                });
                if (
                  (newValue === "hirers" && hirers.length === 0) ||
                  (newValue === "agencies" && agencies.length === 0) ||
                  (newValue === "others" && independent.length === 0)
                ) {
                  (window as any).toast("No contacts in this category", "info"); 
                } else {
                  const el = document.querySelector('div[data-first="true"][data-parent-type="' + e.detail.value + '"]');
                  if (el) {
                    el.scrollIntoView();
                  }
                }
            } }>
              <IonSegmentButton value="hirers">
                  <IonLabel>Hirers</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="agencies">
                  <IonLabel>Agencies</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="others">
                  <IonLabel>Other</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          }
          { section === "list" &&
            <IonButtons slot="end">
              <IonButton onClick={() => {
                const searchBarEl = document.querySelector("ion-searchbar");
                if (searchBarEl) {
                  searchBarEl.setFocus();
                }
              }}>
                <IonIcon icon={search} />
              </IonButton>
            </IonButtons>
          }
          { (section === "search") &&
            <div className="more-accurate-search-guide">
              { (moreAccurateSearchTitle) &&
                <h2>{moreAccurateSearchTitle}</h2>
              }
              <div>
                <button 
                  onClick={() => {
                    setUserGuideOpenSection("sectorssubsectors")
                  }}
                  style={ modal ? {
                    backgroundColor: moreAccurateSearchSectorsSet ? 'gainsboro' : '#FFA400',
                    color: moreAccurateSearchSectorsSet ? 'black' : 'white',
                  } : {} }
                >
                  <IonIcon icon={briefcase} />
                  <span>{moreAccurateSearchSectors}</span>
                </button>
                <button 
                  onClick={() => {
                    setUserGuideOpenSection("postalcode")
                  }}
                  style={ modal ? {
                    backgroundColor: moreAccurateSearchLocationSet ? 'gainsboro' : '#FFA400',
                    color: moreAccurateSearchLocationSet ? 'black' : 'white',
                  } : {} }
                >
                  <IonIcon icon={location} />
                  <span>{moreAccurateSearchLocation}</span>
                </button>
              </div>
            </div>
          }
          { (!modal) ?
            <div className="nonblocking-loading-wrapper" style={(nonBlockingLoading) ? { display: 'flex' } : { display: 'none' }}>
              <CircularProgress />
              {nonBlockingLoading}
            </div>
          : null }
        </IonToolbar>
      </IonHeader>
      <IonContent id="contacts-wrapper" >
        { (modal && nonBlockingLoading) ?
          <div className="modal-loading-wrapper">
            <CircularProgress />
          </div>
        : null }
        { (section === "search") &&
          <div className="contact-list" style={{ height: '100%' }}>
            {SEARCHBAR_DOM}
            {SEARCHBAR_CLEAR_FILTERS_DOM}
            {MIN_CHARS_DOM}
            { (searchResults && searchText) &&
              <div className='no-results-options'>
                {/* { (!searchTextIsEmail) && */}
                  <h2>Can't find your contact?</h2>
                {/* } */}
                <button onClick={() => {
                  const isSearchValueEmail = Utilities.isEmail(searchText);
                  setNewContactModal({
                    type: "hirer",
                    contactEmail: isSearchValueEmail ? searchText : undefined
                  })
                }}>Invite Now</button>
                {/* <div>
                  <button onClick={() => {
                    const isSearchValueEmail = Utilities.isEmail(searchText);
                    setNewContactModal({
                      type: "hirer",
                      contactEmail: isSearchValueEmail ? searchText : undefined
                    })
                  }}>
                    <img src={ShareStandaloneImg} />
                    <span>Add to Share Availability Contact</span>
                  </button>
                  <button onClick={() => {
                    const isSearchValueEmail = Utilities.isEmail(searchText);
                    setNewContactModal({
                      type: "agency",
                      contactEmail: isSearchValueEmail ? searchText : undefined
                    })
                  }}>
                    <img src={AgencyStandaloneImg} />
                    <span>Add as Agency</span>
                  </button>
                  <button 
                    style={{
                      display: 'none'
                    }} 
                    id="add-as-both-btn"
                    onClick={() => {
                      const isSearchValueEmail = Utilities.isEmail(searchText);
                      setNewContactModal({
                        type: "both",
                        contactEmail: isSearchValueEmail ? searchText : undefined
                      })
                    }}
                  >Add as Both</button>
                </div> */}
              </div>
            }
            { (firstOpen || (modal && !localSearchText)) &&
              <div className="guide">
                { modal ?
                  <div className='modal-guide'>
                    <div className='line'></div>
                    <img src={ContactAddOptionsImg} />
                    <h2>
                      <span>Add a contacts' email address</span>
                      <span className='or'>or</span>
                      <span>Search by name for a contact or business</span>
                    </h2>
                  </div>
                  :
                  <div>
                    <img src={ContactsHero} />
                    <h2>Add Agencies that represent you & Contacts to Share Availability with.</h2>
                  </div>
                }
              </div>
            }
            { (!firstOpen && searchResults) &&
              <div className="group scrollable-group">
                { searchResults.length === 0 && <div className="head">Search Results</div> }
                { searchResults.map((hirer, orgI) => { 
                  if (!hirer.matches)
                    return null;

                  if (hirer.isNewOrganisation) {
                    let orgHeadline = hirer.address ? hirer.address + "" : null;
                    return (
                      <ContactListItem
                        key={hirer.thirdPartyId + searchText}
                        isAgency={false}
                        isHirer={false}
                        mode="invite"
                        onEdit={() => {
                          // No contacts
                        }}
                        onOrgClick={(url) => {
                          if (hirer.organisationName) {
                            setNewContactModal({
                              type: hirer.thirdPartyReference === "schoolsmetadata" ? "hirer" : "both",
                              hideTypeOptions: hirer.thirdPartyReference === "schoolsmetadata" ? ["agency", "both"] : undefined,
                              organisationName: hirer.organisationName,
                              contactEmail: hirer.emailDomain && hirer.emailDomain !== "@" ? hirer.emailDomain : undefined,
                            }) // todo maybe pass school urn if known
                          } else {
                            // todo is this being called anymore?
                            setSendInviteMode(null, null, null, null, null, false, hirer.thirdPartyId, hirer.thirdPartyReference, null, null, hirer.emailDomain);
                          }
                        }}
                        first={true}
                        onlyOrgHeader={true}
                        showJustInvitedOnInvite={true}
                        invitedJustNow={false /*TODO*/}
                        everFirst={true}
                        orgHeadline={orgHeadline}
                        orgImageOverride={hirer.emailDomain && hirer.emailDomain !== "@" ? UserAPI.getExtOrgPicture(hirer.emailDomain.replace("@", "")) : (hirer.organisationImageUrl && hirer.organisationImageUrl !== "logo.clearbit.com//" ? (hirer.organisationImageUrl) : null)}
                        contact={{ 
                          headline: "", // contact.headline, 
                          userId: "", // contact.userId, 
                          email: "", // contact.email,
                          companyName: hirer.organisationName,
                          companyId: hirer.organisationId,
                          verified: hirer.verified,
                          name: "" //  contact.firstName + (contact.lastName ? " " + contact.lastName : "") 
                        } as Contact}
                        list="EMPLOYER" 
                      />
                    )
                  }

                  return ( 
                    <div>
                      { hirer.matches.map((contact, i) => {
                        if (contact.firstName.startsWith("Add ") && contact.firstName.endsWith(" manually")) {
                          return null;
                        }

                        return (
                          <ContactListItem
                            key={contact.contactId + searchText}
                            isAgency={hirer.isAgency}
                            isHirer={hirer.isHirer}
                            mode="invite"
                            contactAvgRating={contact.avgRating}
                            contactTotalRatings={contact.totalRatings}
                            onEdit={() => {
                              setNewContactModal({
                                type: hirer.isAgency && hirer.isHirer ? "both" : hirer.isAgency ? "both" : "hirer",
                                hideTypeOptions: hirer.isAgency && !hirer.isHirer ? ["agency"] : undefined,
                                organisationName: hirer.organisationName,
                                organisationId: hirer.organisationId,
                                contactName: contact.firstName + " " + contact.lastName,
                                firstName: contact.firstName,
                                lastName: contact.lastName,
                                userId: contact.userId,
                                contactId: contact.contactId,
                                forceShareOnly: !hirer.isAgency,
                                contactEmail: contact.email || ""
                              })
                              // setSendInviteMode(contact.contactId, contact.email, contact.firstName, contact.lastName, agencies)
                            }}
                            onOrgClick={() => {
                              setNewContactModal({
                                type: hirer.isAgency && hirer.isHirer ? "both" : hirer.isAgency ? "both" : "hirer",
                                hideTypeOptions: hirer.isAgency && !hirer.isHirer ? ["agency"] : undefined,
                                organisationName: hirer.organisationName,
                                organisationId: hirer.organisationId,
                                forceShareOnly: !hirer.isAgency
                              })
                              // if (showJustAgencies) {
                              //   setSendInviteMode(null, null, null, null, agencies, null, null, null, null, null, null, hirer.organisationId, hirer.organisationName);
                              // } else {
                              //   setSendInviteMode(null, null, null, null, [], false, null, null, hirer.organisationId, hirer.organisationName);
                              // }
                            }}
                            hideRatings={hirer.isAgency}
                            showJustInvitedOnInvite={(hirer.isAgency || hirer.isHirer)}
                            invitedJustNow={false /*TODO*/}
                            onClickBlockMessage={invitingContactId === contact.contactId ? "Sending Invite" : undefined}
                            first={i === 0 && (hirer.isAgency || hirer.isHirer) }
                            everFirst={(hirer.isAgency || hirer.isHirer)} 
                            dontRenderContact={showJustAgencies && orgI !== 0}
                            orgImageOverride={hirer.knownUnsignedUpAgency ? hirer.organisationImageUrl : undefined}
                            imageOverride={hirer.knownUnsignedUpAgency ? "https://uk-test-uemobileservices.azurewebsites.net/publicapi/user/image/undefined" : undefined }
                            contact={{ 
                              headline: contact.headline, 
                              userId: contact.userId, 
                              email: contact.email,
                              companyName: hirer.organisationName,
                              companyId: hirer.organisationId,
                              name: contact.firstName + (contact.lastName ? " " + contact.lastName : ""),
                              verified: contact.verified,
                              contactId: contact.contactId,
                              reported: contact.reported,
                            } as Contact}
                            companyVerified={hirer.verified}
                            list="EMPLOYER" 
                          />
                        )
                      })}
                    </div>
                  )
                }) }
                { (searchResults.length === 0 && searchText && !nonBlockingLoading) &&
                  <div className="no-results">
                    <div>
                      <h2>No results</h2>
                      <p>Try searching for something else or enter an email address</p>
                    </div>
                  </div>
                }
                { searchResults.length > 199 &&
                  <div className="no-results">
                    <div>
                      <h2>Too many results</h2>
                      <p>We can only show the top 200 results, please change your search term of filters to see more results.</p>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
        { (section === "list") &&
          <div className="contact-list" style={{ height: '100%' }}>
            { (contactSelection) &&
              <div className="top-banner info">
                <IonIcon icon={informationCircle} />
                <span>Select contacts to send a your 7 day availability to by email.</span>
              </div>
            }
            {SEARCHBAR_DOM}
            {SEARCHBAR_CLEAR_FILTERS_DOM}
            { (section === "list" && nonBlockingLoading === "Searching Online" && !modal) &&
              <div className="group scrollable-group"onClick={() => {
                setSection("search")
              }}>
                <div className="head">Not yet invited Contacts</div>
                <div className="online-search-preview">
                  <div className='searching-wrapper'>
                    <CircularProgress />
                    {nonBlockingLoading}
                  </div>
                </div>
              </div>
            }
            {MIN_CHARS_DOM}
            { (section === "list" && searchResults.length !== 0) &&
              <div className="group scrollable-group">
                <div className="head">Not yet invited Contacts</div>
                <div className="online-search-preview">
                  <div className='results clearfix'>
                    { searchResults.map(org => {
                      return (
                        <div 
                          className='org-contact-pill' data-org={org.organisationId || org.isNewOrganisation ? "true" : "false"}
                          onClick={() => {
                            setSection("search")
                            setTimeout(() => {
                              if (org.matches && org.matches.find(om => om.firstName.indexOf("' manually") !== -1)) {
                                scrollToContactItem("new", "");
                              } else if (org.matches && org.matches.find(om => om.contactId)) {
                                scrollToContactItem("contact", org.matches[0].contactId);
                              } else if (org.organisationId) {
                                scrollToContactItem("org-internal", org.organisationId);
                              } else if (org.organisationName) {
                                scrollToContactItem("org-external", org.organisationName);
                              }
                            }, 250);
                          }}
                        >
                          { (org.organisationId || (org.organisationImageUrl && imagesFailedToLoad.indexOf(org.organisationImageUrl) === -1) || (org.isNewOrganisation && org.emailDomain && org.emailDomain.length > 1)) &&
                            <img 
                              className="org-image" 
                              src={ 
                                org.organisationId
                                ? UserAPI.getOrgPicture(org.organisationId) 
                                : org.emailDomain 
                                ? UserAPI.getExtOrgPicture(org.emailDomain.replace("@", "")) 
                                : org.organisationImageUrl
                              } 
                              onError={() => {
                                setImagesFailedToLoad([
                                  ...imagesFailedToLoad,
                                  org.organisationImageUrl
                                ])
                              }}
                            />
                          }
                          { (org.organisationName) &&
                            <div className="name">{org.organisationName}</div>
                          }
                          { (!org.isNewOrganisation) &&
                            <div className='contacts' style={ org.organisationId || org.isNewOrganisation ? { width: org.matches ? (org.matches.length === 1 ? 32 : org.matches.length === 2 ? 48 : 64) : 0 } : { } }>
                              { org.matches && org.matches[0] && org.matches.slice(0, 3).map(contact => {
                                return (
                                    <div className='org-contact'>
                                      { (contact.firstName.indexOf("not found?") === -1) ?
                                        <img className="contact-image" src={UserAPI.getProfilePicture(contact.userId)} /> :
                                        <div className="contact-image"></div>
                                      }
                                      { (!org.organisationId) &&
                                        <div className="name" style={{ padding: '0 8px', textAlign: contact.firstName.indexOf("not found?") !== -1 ? "center" : undefined }}>{contact.firstName + (contact.lastName ? " " + contact.lastName : "")}</div>
                                      }
                                    </div>
                                )
                              }) }
                            </div>
                          }
                        </div>
                      )
                    })}
                  </div>
                  <div 
                    className='show-all-promo'
                    onClick={() => {
                      setSection("search")
                    }}
                  >
                    View and Invite more contacts
                    <IonIcon icon={chevronForwardCircle} />
                  </div>
                </div>
              </div>
            }
            { (outgoings && window.location.pathname !== "/selectreferral" && outgoings.length !== 0 && !contactSelection && !showJustAgencies && !sharingFilesSelection) &&
              <div className="group scrollable-group">
                <div className="head" onClick={() => {
                  setHelpSection("sent-invites")
                }}>
                  Sent Invites
                  <IonIcon icon={helpCircle} />
                </div>
                { outgoings.map((hirer, i) => { return ( <div> { hirer.contacts.map((contact, i) => {
                  return (
                    <ContactListItem 
                      key={contact.contactId + searchText}
                      orgImageOverride={contact.thirdPartyLogoUrl || contact.organisationId} 
                      everFirst={false} 
                      onEdit={(name) => {
                        setOptionsEmail(contact.email)
                        setOptionsUserName(name); 
                        setOptionsCanRemoveContact(true);
                        setOptionsUserId(contact.userId); 
                        setOptionsConactId(contact.contactId);
                        setOptionsContactId(contact.contactId);
                        setOptionsContactOrgTempId(hirer.contacts[0].firstName === "No contact at this agency" ? hirer.organisationId : null);
                        setOptionsContactType("outgoing");
                      }}
                      sharingAvailabilityWithContact={true}
                      dontRenderOrg={localSearchTextFormatted !== ""}
                      dontRenderContact={(localSearchTextFormatted && !contactSearchMatch(localSearchTextFormatted, contact.firstName, contact.lastName))}
                      first={false}
                      contactRating={contact.rating}
                      contact={{ 
                        contactId: contact.contactId,
                        headline: contact.headline, 
                        emailDeliveryStatusTypeId: contact.emailDeliveryStatusTypeId, 
                        notificationStatusTypeId: contact.notificationStatusTypeId, 
                        inviteIgnored: contact.inviteIgnored, 
                        email: contact.organisationName || contact.thirdPartyOrganisationName || contact.email, 
                        userId: contact.userId, 
                        companyName: hirer.organisationName, 
                        companyId: contact.organisationId || hirer.organisationId, 
                        name: contact.firstName + (contact.lastName ? " " + contact.lastName : ""),
                        verified: contact.verified,
                        reported: contact.reported,
                      } as Contact} 
                      list="EMPLOYER"
                    /> 
                  ) } )} </div> ) } ) }
              </div>
            }
            { (hirers && hirers.length !== 0) &&
              <div className="group scrollable-group indent">
                <div className="head" data-section="hirers">
                  { (section === "list" && searchResults.length !== 0) &&
                    <span>Existing{' '}</span>
                  }
                  Hirers
                </div>
                { hirers.map(hirer => { return ( <div> { hirer.contacts.map((contact, i) => {
                  if ( (hirer.hasAgreementWith && showJustAgencies) || (hirer.isSharingWith && !showJustAgencies) )
                    return (
                      <ContactListItem 
                        showNoRatings={true}
                        key={contact.contactId + searchText}
                        everFirst={true} 
                        onEdit={(name) => {
                          if (sharingFilesSelection) {
                            toggleFileShare(shareUpdates, sharingWithUserIds, contact.userId)
                          } else if (contactSelection) {
                            if (contact.firstName !== "No contact at this agency") {
                              toggleSelectedContact(contact.contactId, selectedContacts);
                            }
                          } else if (window.location.pathname === "/selectreferral") {
                            selectReferral(contact, "hirer", hirer.organisationName);
                          } else {
                            setOptionsUserName(name); setOptionsUserId(contact.userId); setOptionsContactId(contact.contactId); setOptionsContactOrgTempId(hirer.contacts[0].firstName === "No contact at this agency" ? hirer.organisationId : null); setOptionsContactType("hirer"); setOptionsOrgId(hirer.organisationId); setOptionsOrgType("hirer")
                            setOptionsCanRemoveContact(true);
                          }
                        }}
                        orgIsRepresentingAgency={hirer.hasAgreementWith}
                        sharingAvailabilityWithContact={true /*once the hirer org signs up it must be only a sharing relationship otherwise wouldn't be returned here */}
                        dontRenderOrg={localSearchTextFormatted !== ""}
                        dontRenderContact={(localSearchTextFormatted && !contactSearchMatch(localSearchTextFormatted, contact.firstName, contact.lastName))}
                        selected={selectedContacts.indexOf(contact.contactId) !== -1}
                        selecting={contactSelection}
                        first={i === 0} 
                        parentType="hirers"
                        companyVerified={hirer.verified}
                        contactRating={contact.rating}
                        organisationRating={hirer.rating}
                        organisationName={hirer.organisationName}
                        contact={{ reported: contact.reported, contactId: contact.contactId, verified: contact.verified, headline: contact.headline, emailDeliveryStatusTypeId: contact.emailDeliveryStatusTypeId, notificationStatusTypeId: contact.notificationStatusTypeId, inviteIgnored: contact.inviteIgnored,  email: contact.email, userId: contact.userId, companyName: hirer.organisationName, companyId: hirer.organisationId, name: contact.firstName + (contact.lastName ? " " + contact.lastName : "") } as Contact} 
                        list="EMPLOYER"
                        highlightBlock={sharingWithUserIds.indexOf(contact.userId) !== -1}
                        hideRatings={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                        hideOptions={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                        hideOrgOrgOptions={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                        contactAvgRating={contact.avgRating}
                        contactTotalRatings={contact.totalRatings}
                        orgAvgRating={hirer.avgRatingStars}
                        orgTotalRatings={hirer.totalRatings}
                        onOrgClick={() => {
                          if (sharingFilesSelection || window.location.pathname === "/selectreferral") {
                            return;
                          }
                          setOptionsOrgId(hirer.organisationId);
                          setOptionsOrgName(hirer.organisationName);
                          setOptionsCanRemoveContact(true);
                          setOptionsOrgType("hirer");
                        }}
                        ignoreOrgClick={!!sharingFilesSelection}
                        rate={() => {
                          setRateModal({ userId: contact.userId, organisationId: hirer.organisationId, organisationType: "hirer", organisationNameJustStr: hirer.organisationName, organisationName: null, userName: contact.firstName + " " + contact.lastName, uContactId: contact.contactId })
                        }}
                      />
                    ) 
                    return null;
                  }
                )} </div> ) }) }
              </div>
            }
            { (agencies && agencies.length !== 0) &&
              <div className="group scrollable-group indent">
                <div className="head" data-section="agencies">
                  { (section === "list" && searchResults.length !== 0) &&
                    <span>Existing{' '}</span>
                  }
                  Agencies
                </div>
                { agencies.map(hirer => { return ( <div> { hirer.contacts.map((contact, i) => {
                  if ( (hirer.hasAgreementWith && showJustAgencies) || (hirer.isSharingWith && !showJustAgencies) || true ) // todo maybe remove
                    return (
                      <ContactListItem 
                        showNoRatings={true}
                        key={contact.contactId + searchText}
                        everFirst={true} 
                        onEdit={(name) => { 
                          if (sharingFilesSelection) {
                            toggleFileShare(shareUpdates, sharingWithUserIds, contact.userId)
                          } else if (contactSelection) {
                            if (contact.firstName !== "No contact at this agency") {
                              toggleSelectedContact(contact.contactId, selectedContacts);
                            }
                          } else if (window.location.pathname === "/selectreferral") {
                            selectReferral(contact, "agency", hirer.organisationName);
                          } else {
                            setOptionsUserName(name); setOptionsUserId(contact.userId); setOptionsContactId(contact.contactId); setOptionsContactOrgTempId(hirer.contacts[0].firstName === "No contact at this agency" ? hirer.organisationId : null); setOptionsContactType("agency"); setOptionsOrgId(hirer.organisationId); setOptionsOrgType("agency")
                            setOptionsCanRemoveContact(Utilities.intOrNaN(hirer.organisationId) ? false : true); 
                            if (hirer.verificationRejectedByContactId) {
                              setVerificationRejectedByContactId(hirer.verificationRejectedByContactId);
                            }
                          }
                        }}
                        externalOrgLogo={
                          (!hirer.isSharingWith && hirer.hasAgreementWith && contact.email) ? contact.email.split("@")[1] :
                          hirer.organisationDomain ? hirer.organisationDomain :
                          null
                        }
                        sharingAvailabilityWithContact={contact.contactId ? true : false}
                        contactAvgRating={contact.avgRating}
                        contactTotalRatings={contact.totalRatings}
                        dontRenderOrg={localSearchTextFormatted !== ""}
                        dontRenderContact={contact.firstName === "No contact at this agency" || (localSearchTextFormatted && !contactSearchMatch(localSearchTextFormatted, contact.firstName, contact.lastName))}
                        selected={selectedContacts.indexOf(contact.contactId) !== -1}
                        selecting={contactSelection}
                        orgIsRepresentingAgency={hirer.hasAgreementWith}
                        first={i === 0}
                        parentType="agencies"
                        companyVerified={hirer.verified}
                        contactRating={contact.rating}
                        organisationRating={hirer.rating}
                        orgAvgRating={hirer.avgRatingStars}
                        orgTotalRatings={hirer.totalRatings}
                        contact={{ reported: contact.reported, contactId: contact.contactId, verified: contact.verified, headline: contact.headline, emailDeliveryStatusTypeId: contact.emailDeliveryStatusTypeId, notificationStatusTypeId: contact.notificationStatusTypeId, inviteIgnored: contact.inviteIgnored,  email: contact.email, userId: contact.userId, companyName: hirer.organisationName, companyId: hirer.organisationId, name: contact.firstName + (contact.lastName ? " " + contact.lastName : "") } as Contact} 
                        list="EMPLOYER"
                        onOrgClick={() => {
                          if (sharingFilesSelection || window.location.pathname === "/selectreferral") {
                            return;
                          }
                          if (hirer.verificationRejectedByContactId) {
                            setVerificationRejectedByContactId(hirer.verificationRejectedByContactId);
                          }
                          setOptionsOrgId(hirer.organisationId);
                          setOptionsOrgName(hirer.organisationName);
                          setOptionsOrgType("agency");
                          setOptionsOrgTempId(Utilities.intOrNaN(hirer.organisationId) ? parseInt(hirer.organisationId) : null);
                          setOptionsCanRemoveContact(Utilities.intOrNaN(hirer.organisationId) ? false : true)
                          setOptionsRepresentingAgency(hirer.hasAgreementWith ? true : false);
                          setOptionsMainContactName((hirer.contacts[0] && hirer.contacts[0].firstName && hirer.contacts[0].firstName !== "No contact at this agency") ? hirer.contacts[0].firstName + (hirer.contacts[0].lastName ? (" " + hirer.contacts[0].lastName) : "" ) : null);
                          setOptionsMainContactEmail(hirer.contacts[0] ? hirer.contacts[0].email : null);
                          setOptionsMainContactId(hirer.contacts[0] ? hirer.contacts[0].contactId : null);
                          setOptionsOrgTypeTemp(Utilities.intOrNaN(hirer.organisationId) ? (hirer.contacts[0] && hirer.contacts[0].contactId ? "both" : "agency") : "agency");
                        }}
                        rate={() => {
                          setRateModal({ userId: contact.userId, organisationId: hirer.organisationId, organisationType: "agency", organisationName: null, organisationNameJustStr: hirer.organisationName, userName: contact.firstName + " " + contact.lastName, uContactId: contact.contactId })
                        }}
                        highlightBlock={sharingWithUserIds.indexOf(contact.userId) !== -1}
                        hideRatings={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                        hideOptions={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                        hideOrgOrgOptions={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                      />
                    )
                  return null;
                } )} </div> ) }) }
              </div>
            }
            { (independent && independent.length !== 0 && !showJustAgencies) &&
              <div className="group scrollable-group">
                <div className="head" data-section="others">
                  { (section === "list" && searchResults.length !== 0) &&
                    <span>Existing{' '}</span>
                  }
                  Other
                </div>
                { independent.map(hirer => { return ( <div> { hirer.contacts.map((contact, i) => 
                  <ContactListItem 
                    sharingAvailabilityWithContact={true}
                    showNoRatings={true}
                    key={contact.contactId + searchText}
                    everFirst={false} 
                    first={i === 0}
                    dontRenderOrg={true}
                    parentType="others"
                    contactRating={contact.rating}
                    onEdit={(name) => { 
                      if (sharingFilesSelection) {
                        toggleFileShare(shareUpdates, sharingWithUserIds, contact.userId)
                      } else if (contactSelection) {
                        if (contact.firstName !== "No contact at this agency") {
                          toggleSelectedContact(contact.contactId, selectedContacts);
                        }
                      } else if (window.location.pathname === "/selectreferral") {
                        selectReferral(contact, "worker");
                      } else {
                        setOptionsUserName(name); setOptionsUserId(contact.userId); setOptionsContactId(contact.contactId);  setOptionsContactOrgTempId(hirer.contacts[0].firstName === "No contact at this agency" ? hirer.organisationId : null); setOptionsContactType("independent")
                        setOptionsCanRemoveContact(true);
                      }
                    }}
                    contactAvgRating={contact.avgRating}
                    contactTotalRatings={contact.totalRatings}
                    dontRenderContact={(localSearchTextFormatted && !contactSearchMatch(localSearchTextFormatted, contact.firstName, contact.lastName))}
                    selected={selectedContacts.indexOf(contact.contactId) !== -1}
                    selecting={contactSelection}
                    contact={{ reported: contact.reported, contactId: contact.contactId, verified: contact.verified, headline: contact.headline, emailDeliveryStatusTypeId: contact.emailDeliveryStatusTypeId, notificationStatusTypeId: contact.notificationStatusTypeId, inviteIgnored: contact.inviteIgnored,  email: contact.email, userId: contact.userId, companyName: hirer.organisationName, companyId: hirer.organisationId, name: contact.firstName + (contact.lastName ? " " + contact.lastName : "") } as Contact} 
                    list="EMPLOYER"
                    rate={() => {
                      setRateModal({ userId: contact.userId, organisationId: null, organisationType: "independent", organisationName: null, userName: contact.firstName + " " + contact.lastName, uContactId: contact.contactId })
                    }}
                    highlightBlock={sharingWithUserIds.indexOf(contact.userId) !== -1}
                    hideRatings={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                    hideOptions={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                    hideOrgOrgOptions={sharingFilesSelection || window.location.pathname === "/selectreferral"}
                  />
                )} </div> ) }) }
              </div>
            }
          </div>
        }
        { (keyboardOpen) &&
            <div className="keyboard-hide">
                <IonIcon icon={caretDownCircle} />
            </div>
        }
        { (!modal) &&
          <div className="bottom-fab-options" data-keyboard-open={keyboardOpen}>
            { !contactSelection && !sharingFilesSelection && !keyboardOpen && window.location.pathname !== "/selectreferral" &&
              <button onClick={() => { 
                if (showJustAgencies) {
                  history.goBack();
                } else {
                  if (section === "search") {
                    InternalTracker.trackEvent("Search New Contacts Inited");
                    setSearchResults([]);
                    setSearchText("")
                    setLocalSearchText("")
                    reloadContacts();
                    history.replace("/contacts") // rewrite url to prevent this view opening when navigating back
                  }
                  if (section === "list") {
                    setSearchResults([]);
                    setSearchText("");
                    setLocalSearchText("")
                    setFirstOpen(true);
                    setTimeout(() => {
                      const searchbarEl = document.querySelector("ion-searchbar") as HTMLIonSearchbarElement;
                      if (searchbarEl) {
                        searchbarEl.setFocus();
                      }
                    }, 100)
                  }
                  setSection(section === "search" ? "list" : "search");
                }
              }} 
                style={ section === "search" ? { backgroundColor: "#333" } : { } } 
                data-i={1}
                className={"add-btn " + (!showJustAgencies && section !== "search" ? "limit-width" : "")}
              >
                <IonIcon icon={ keyboardOpen ? chevronBackCircle : showJustAgencies ? arrowBackOutline : section === "search" ? closeCircle : addCircle} />
                <span>{ showJustAgencies ? "Back to Offer" : section === "search" ? "Back to contacts" : "Add contacts"}</span>
              </button>
            }
            { (section === "list" && window.location.pathname !== "/selectreferral" && !sharingFilesSelection && !keyboardOpen) &&
              <button
                style={{
                  backgroundColor: "#333",
                  marginLeft: 12
                }}
                className="add-btn limit-width"
                onClick={() => {
                  if (contactSelection) {
                    setContactSelection(null)
                  } else {
                    setExternalShareSelection(true); 
                  }
                }}
                data-i={2}
              >
                <IonIcon icon={contactSelection ? arrowBackOutline : share} />
                <span>{ contactSelection ? "Back" : "Share Availability" }</span>
              </button>
            }
            { (localStorage.getItem("FeatureReferrals") && section === "list" && !sharingFilesSelection && !keyboardOpen && window.location.pathname !== "/selectreferral") &&
            <button
                style={{
                  backgroundColor: "#333",
                  marginLeft: 12
                }}
                className="add-btn limit-width"
                onClick={() => {
                  history.push("/referrals/add")
                }}
                data-i={3}
              >
                <IonIcon icon={share} />
                <span>Refer Contact</span>
              </button>
            }
            { (contactSelection && !sharingFilesSelection && window.location.pathname !== "/selectreferral") &&
              <button
                style={{
                  marginLeft: 12,
                  opacity: sendEmailButtonStatus ? 0.6 : 1
                }}
                className={"add-btn " + (!sendEmailButtonStatus && selectedContacts.length !== 0 ? "" : "limit-width")}
                onClick={() => {
                  if (selectedContacts.length === 0) {
                    (window as any).toast("Select at least one contact")
                  } else {
                    sendAvailabilityEmail(selectedContacts);
                  }
                }}
              >
                <IonIcon icon={share} />
                <span>{ sendEmailButtonStatus || (selectedContacts.length !== 0 ? "Send Email to " + selectedContacts.length : "Send Email") }</span>
              </button>
            }
            { sharingFilesSelection &&
              <button
                className="add-btn"
                style={{
                  backgroundColor: "#FB5B5A",
                  marginRight: 12
                }}
                onClick={() => {
                  setTimeout(() => {
                    window.location.href = "/"
                  }, 1)
                }}
              >
                Cancel
              </button>
            }
            { sharingFilesSelection &&
              <button
                className="add-btn"
                id="update-share-btn"
                onClick={() => {
                  updateShare(sharedFileId, shareUpdates, sharingFilesType);
                }}
              >
                <IonIcon icon={checkmarkDone} />
                Update Share
              </button>
            }
          </div>
        }

        { (confirmContactRemove !== null) &&
          <IonAlert
            isOpen={confirmContactRemove !== null}
            onDidDismiss={() => setConfirmOrgContactRejectedAddAsIndividual(false)}
            header={"Do you want to block " + (confirmContactRemove.length > 1 ? "these" : "this") + " contact" + (confirmContactRemove.length > 1 ? "s" : "") + "?"}
            message={"This will prevent them from seeing your availability, sending you offers or messaging you."}
            buttons={[
              {
                text: 'Yes, Block Contact' + (confirmContactRemove.length > 1 ? "s" : ""),
                handler: () => {
                  for (let i = 0; i < confirmContactRemove.length; i++) {
                    removeContact(confirmContactRemove[i], null);
                  }
                  setConfirmContactRemove(null);
                },
              },
              {
                text: 'No, Continue Sharing',
                handler: () => {
                  setConfirmContactRemove(null);
                },
              },
            ]}
          />
        }

        { (helpSection !== null) &&
          <IonAlert
            isOpen={helpSection !== null}
            onDidDismiss={() => setConfirmOrgContactRejectedAddAsIndividual(false)}
            header={helpSection === "sent-invites" ? "Contacts you share your availability with via automated emails, but have not created an Updatedge account yet. Once they do, they can book you in & chat directly with you. Give them a call!" : ""}
            buttons={[
              {
                text: 'Close',
                handler: () => {
                  setHelpSection(null);
                },
              },
            ]}
          />
        }

        <IonActionSheet
          header={optionsUserName}
          isOpen={optionsUserId !== null && optionsOrgName === null}
          onDidDismiss={() => {
            setOptionsUserId(null);
            setOptionsEmail(null);
            setVerificationRejectedByContactId(null);
          }}
          buttons={[ optionsCanRemoveContact ? {
            text: optionsContactOrgTempId ? 'Remove Agency' : 'Delete and stop sharing',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (window.confirm(optionsContactOrgTempId ? 'Are you sure you want to remove this Agency' : 'Are you sure you want to stop sharing your availability with this contact')) {
                removeContact(optionsContactId, optionsContactOrgTempId);
              }
            }
          } : null,
          optionsUserId ? {
            text: "Report Profile",
            icon: flag,
            handler: () => {
              setReportConfirmContactId(optionsContactId);
            }
          } : null,
          verificationRejectedByContactId ? {
            text: "Verification Rejected. Tap to ask again",
            icon: alertCircle,
            handler: () => {
              requestReVerification(verificationRejectedByContactId);
            }
          } : null,
          {
            text: 'Give or review rating for ' + optionsUserName,
            icon: starHalf,
            handler: () => {
              if (optionsContactType === "outgoing") {
                setRateModal({
                  contactId: optionsContactId,
                  organisationId: null,
                  organisationType: "independent",
                  organisationName: null,
                  userName: optionsUserName,
                  uContactId: optionsContactId,
                  organisationNameJustStr: optionsOrgName,
                });
              } else {
                const hirerOrgMatches = hirers.find(hirer => hirer.organisationId === optionsOrgId);
                const agencyOrgMatches = agencies.find(hirer => hirer.organisationId === optionsOrgId);
                setRateModal({
                  userId: optionsUserId,
                  organisationId: optionsOrgId,
                  organisationType: optionsOrgType,
                  organisationName: optionsOrgName,
                  userName: optionsUserName,
                  uContactId: optionsContactId,
                  organisationNameJustStr: hirerOrgMatches ? hirerOrgMatches.organisationName : agencyOrgMatches ? agencyOrgMatches.organisationName : optionsOrgName,
                });
              }
            }
          },
          (optionsEmail !== null ? {
            text: 'Edit Email',
            icon: create,
            handler: () => { setEmailEditingAlert(true); }
          } : {
            text: 'Send Message',
            icon: chatbubbleEllipses,
            handler: () => { 
              if (optionsContactType === "outgoing" || optionsContactOrgTempId) {
                (window as any).toast("This user hasn't signed up yet")
              } else {
                localStorage.setItem("disablePushReRegisterOnInit", "true");
                history.push("/messages/" + optionsUserId + "/auto");
              }
            }
          }), {
            text: 'View Files',
            icon: fileTrayFull,
            handler: () => {
              if (optionsOrgId) {
                history.push("/files/org/" + optionsOrgId);
              } else {
                history.push("/files/user/" + optionsUserId);
              }
            }
          }, ((optionsContactType === "hirer" || optionsContactType === "agency") && localStorage.getItem("FeatureReferrals")) ? {
            text: 'Refer ' + (optionsContactType === "hirer" ? "an Agency" : "a Hirer") + ' to this Contact',
            icon: share,
            handler: () => {
              localStorage.setItem("referral-state", JSON.stringify({
                "step": 32,
                "referredPartyType": optionsContactType === "hirer" ? "hirer" : "agency",
                "referralRecipientType": optionsContactType === "hirer" ? "agency" : "hirer",
                "email": "",
                "nameFirst": optionsUserName.split(" ")[0],
                "nameLast": optionsUserName.split(" ")[1] || "",
                "orgName": optionsOrgName,
                "userId": optionsUserId,
                "email2": "",
                "nameFirst2": "",
                "nameLast2": "",
                "orgName2": "",
                "userId2": "",
              }))
              history.push("/referrals/add")
            }
          } : null, , ((optionsContactType === "hirer" || optionsContactType === "agency") && localStorage.getItem("FeatureReferrals")) ? {
            text: 'Refer a Worker to this Contact',
            icon: share,
            handler: () => {
              localStorage.setItem("referral-state", JSON.stringify({
                "step": 32,
                "referredPartyType": optionsContactType === "hirer" ? "hirer" : "agency",
                "referralRecipientType": "worker",
                "email": "",
                "nameFirst": optionsUserName.split(" ")[0],
                "nameLast": optionsUserName.split(" ")[1] || "",
                "orgName": optionsOrgName,
                "userId": optionsUserId,
                "email2": "",
                "nameFirst2": "",
                "nameLast2": "",
                "orgName2": "",
                "userId2": "",
              }))
              history.push("/referrals/add")
            }
          } : null, {
            text: 'Close',
            role: 'cancel',
            handler: () => { }
          }].filter(item => item !== null)}
        >
        </IonActionSheet>
        <IonActionSheet
          header={"Share your next 7 days availability With OTHERS (not in your Updatedge contacts list)"}
          subHeader="Existing contacts can see your up-to-date availability at any time"
          isOpen={externalShareSelection !== null}
          onDidDismiss={() => {
            setExternalShareSelection(null);
          }}
          buttons={[{
            text: 'via Email',
            handler: () => { 
              InternalTracker.trackEvent("Availability Share via Email Inited");
              setContactSelection(true)
            }
          }, {
            text: 'via WhatsApp, Facebook, Signal...)',
            handler: () => {
              InternalTracker.trackEvent("Availability Share via Other Apps Inited");
              shareAvailabilityExternally();
            }
          }, {
            text: 'Close',
            role: 'cancel',
            handler: () => { }
          }]}
        >
        </IonActionSheet>
        <IonActionSheet
          header={optionsOrgName}
          isOpen={optionsOrgName !== null}
          onDidDismiss={() => {
            setOptionsOrgId(null);
            setOptionsOrgName(null);
            setOptionsCanRemoveContact(null);
            setVerificationRejectedByContactId(null);
          }}
          buttons={[{
            text: 'Give or review rating for ' + optionsOrgName,
            icon: starHalf,
            handler: () => {setRateModal({
              organisationId: optionsOrgId,
              organisationType: optionsOrgType,
              organisationName: optionsOrgName,
            }); }
          }, {
            text: 'View Files',
            icon: fileTrayFull,
            handler: () => {
              if (optionsOrgId) {
                history.push("/files/org/" + optionsOrgId);
              } else {
                history.push("/files/user/" + optionsUserId);
              }
            }
          }, verificationRejectedByContactId ? {
            text: "Verification Rejected. Tap to ask again",
            icon: alertCircle,
            handler: () => {
              requestReVerification(verificationRejectedByContactId) 
              // todo handle temporgverify
            }
          } : null, (optionsOrgTempId || (optionsOrgId && optionsRepresentingAgency)) ? {
            text: 'Edit Details',
            icon: pencil,
            handler: () => {
              setNewContactModal({
                previousState: {
                  organisationName: optionsOrgName,
                  contactName: optionsMainContactName,
                  firstName: optionsMainContactName.split(" ")[0],
                  lastName: optionsMainContactName.split(" ").slice(1).join(" "),
                  contactEmail: optionsMainContactEmail,
                  type: optionsOrgTypeTemp,
                  organisationId: optionsOrgId,
                  contactId: optionsMainContactId,
                },
                organisationName: optionsOrgName,
                contactName: optionsMainContactName,
                firstName: optionsMainContactName.split(" ")[0],
                lastName: optionsMainContactName.split(" ").slice(1).join(" "),
                contactEmail: optionsMainContactEmail,
                type: optionsOrgTypeTemp,
                organisationId: optionsOrgId,
                contactId: optionsMainContactId,
                filledOrganisationFromSuggestions: Utilities.intOrNaN(optionsOrgId) ? false : true,
              })
            }
          } : null, (optionsOrgTempId || (optionsOrgId && optionsRepresentingAgency)) ? {
            text: 'Remove Agency Representation',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (window.confirm('Are you sure you want to remove this agency?')) {
                removeContact(optionsContactId, optionsOrgTempId || optionsOrgId);
              }
            }
          } : null, (!(optionsOrgTempId || (optionsOrgId && optionsRepresentingAgency)) && optionsOrgType === "agency") ? {
            text: 'Set as Representating Agency',
            icon: addCircle,
            handler: async () => {
              setBlockingLoading("Creating Connection...")
              await ContactAPI.addAgencyById(optionsOrgId).then(() => {
                setSuccessToast("Agency Added");
                reloadContacts();
              }).catch(e => {
                setErrorToast("Error Adding Agency");
              })
              setBlockingLoading(null);
            }
          } : null, {
            text: 'Close',
            role: 'cancel',
            handler: () => { }
          }].filter(item => item !== null)}
        ></IonActionSheet>
        <IonToast
          isOpen={successToast !== null}
          color="success"
          onDidDismiss={() => setSuccessToast(null)}
          message={successToast}
          position="top"
          duration={2500}
          buttons={[ { text: 'Hide', role: 'cancel' } ]}
        />
        <IonToast
            isOpen={errorToast !== null}
            color="danger"
            onDidDismiss={() => setErrorToast(null)}
            message={errorToast}
            position="top"
            duration={2500}
            buttons={[ { text: 'Hide', role: 'cancel' } ]}
        />

        { (!modal && !keyboardOpen) &&
          <OnboardingGuide section="contacts" hide={section !== "list" || window.location.pathname === "/selectreferral"} />
        }

        <IonAlert
          isOpen={confirmOrgContactRejectedAddAsIndividual}
          onDidDismiss={() => setConfirmOrgContactRejectedAddAsIndividual(false)}
          header={"The company has already indicated that this contact does not belong to them. Do you want to add them as an individual account?"}
          buttons={[
            {
              text: 'Yes',
              handler: () => {
                sendExtendedInvite(
                  inviteParams,
                  inviteMode,
                  false,
                  true
                )
              },
            },
            {
              text: 'No, cancel adding',
              handler: () => {
                setInviteMode(null);
              },
            },
            {
              text: 'No, cancel adding, and report',
              handler: () => {
                setInviteMode(null);
                window.open("mailto:support@updatedge.com?subject=Version 2 Organization Membership Rejected Report [" + inviteParams.email + ", " + inviteParams.orgId + "]")
              },
            },
          ]}
        />

        <IonAlert
          isOpen={confirmDifferentOrgContactAddOrgName !== ""}
          onDidDismiss={() => setConfirmDifferentOrgContactAddOrgName("")}
          header={"This email is already signed up and a member of the " + confirmDifferentOrgContactAddOrgName + " organization. Do you want to continue adding this contact to " + confirmDifferentOrgContactAddOrgName + "?"}
          buttons={[
            {
              text: 'Yes, add it to ' + confirmDifferentOrgContactAddOrgName,
              handler: () => {
                sendExtendedInvite(
                  inviteParams,
                  inviteMode,
                  false,
                  true
                )
              },
            },
            {
              text: 'No, cancel adding',
              handler: () => {
                setInviteMode(null);
              },
            },
            {
              text: 'No, cancel adding, and report',
              handler: () => {
                setInviteMode(null);
                window.open("mailto:support@updatedge.com?subject=Version 2 Organization Membership Report [" + inviteParams.email + ", " + inviteParams.orgId + "]")
              },
            },
          ]}
        />

        <IonAlert
          isOpen={emailEditingAlert}
          onDidDismiss={() => { setEmailEditingAlert(false) }}
          header={'Edit email'}
          inputs={[
            {
              name: 'email',
              type: 'text',
              value: emailEditingAlert ? optionsEmail : "",
              placeholder: 'Email Address'
            }
          ]}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: 'Resend',
              handler: (data) => {

                let email = data.email;
                let name = optionsUserName

                if (!email || !email.trim()) {
                  (window as any).toast("Please enter the email of your contact to send an invitation")
                  return;
                }

                if (!Utilities.isEmail(email.trim())) {
                  (window as any).toast("Invalid Email Address")
                  return;
                }

                InternalTracker.trackEvent("Contact Invited", {
                  name: name,
                  email: email,
                  type: "resend"
                })

                Promise.all([ContactAPI.remove(optionsConactId), ContactAPI.invite(undefined, email, name, true)]).then(() => {
                  setSuccessToast("Email updated");
                  setSection("list");
                  setSearchResults([]);
                  setSearchText("")
                  setFirstOpen(true);
                  reloadContacts();
                  ChatService.reloadContacts();
                }).catch(e => {
                  handleInviteErr(e)
                })

              }
            }
          ]}
        />

      { userGuideOpenSection !== null &&
        <ProfileProgress
          noRemindLaterSet={true}
          explicitOpen={true}
          hideNavigation={true}
          autoSelectSection={userGuideOpenSection}
          onClosed={() => {
            setUserGuideOpenSection(null)
            reloadUserSettings();
            if (searchText) {
              searchContacts(searchText, showJustAgencies)
            }
          }}
        />
      }
      <IonModal
        isOpen={newContactModal !== null}
        canDismiss={newContactModal === null}
        presentingElement={(contentRef && contentRef.current) ? contentRef.current : null}
        onDidDismiss={() => { 
          setNewContactModal(null) 
        }}
        data-modal="new-contact-modal"
        mode="ios"
        animated={false}
      >
        <IonHeader mode="ios">
          <IonToolbar>
            <IonTitle>{(newContactModal && newContactModal.previousState ? "Edit" : "Confirm")} Contact Details</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setNewContactModal(null)
              }}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className='content'>
            { (keyboardOpen) &&
              <div className="keyboard-hide">
                <IonIcon icon={caretDownCircle} />
              </div>
            }
            <IonList>
              { (newContactModal) &&
                <IonItem>
                  <IonIcon icon={business} slot="start" />
                  { (newContactModal /* && newContactModal.filledOrganisationFromSuggestions*/ && newContactModal.organisationId) &&
                    <img src={UserAPI.getOrgPicture(newContactModal.organisationId)} />
                  }
                  <IonInput
                    placeholder="Organisation's Name"
                    id="organisations-name-input"
                    disabled={newContactModal && newContactModal.filledOrganisationFromSuggestions || !(newContactModal && (!newContactModal.organisationId || newContactModal.filledOrganisationFromSuggestions || newContactModal.previousState))}
                    value={newContactModal?.organisationName}
                    onIonInput={(e) => {
                      if (newContactModal && (!newContactModal.organisationId || newContactModal.previousState)) {
                        const orgName = Utilities.capitalizeEachFirstLetter((e.target as HTMLIonInputElement).value.toString());
                        setNewContactModal({
                          ...newContactModal,
                          organisationName: orgName,
                          organisationId: null,
                        })
                        getOrganisationSuggestions(orgName);
                      }
                    }}
                  />
                  { (newContactModal.filledOrganisationFromSuggestions) &&
                    <IonIcon 
                      icon={closeSharp} 
                      slot="end"
                      onClick={() => {
                        setNewContactModal({
                          ...newContactModal,
                          organisationId: null,
                          filledOrganisationFromSuggestions: false,
                          organisationName: "",
                        })
                      }}
                    />
                  }
                </IonItem>
              }
              { (organisationSuggestions && organisationSuggestions.length !== 0 && newContactModal && !newContactModal.organisationId) &&
                <div className='suggestions-wrapper'>
                  <p>Tap on the organisation, if you recognize it.</p>
                  <div className='suggestions'>
                    { organisationSuggestions.map((org, i) => {
                      return (
                        <span
                          key={org.id}
                          onClick={() => {
                            setNewContactModal({
                              ...newContactModal,
                              organisationId: org.id,
                              filledOrganisationFromSuggestions: true,
                              organisationName: org.name,
                            })
                            setOrganisationSuggestions([]);
                          }}
                        >
                          <img src={UserAPI.getOrgPicture(org.id)} />
                          <span>{org.name}</span>
                        </span>
                      )
                    }) }
                  </div>
                </div>
              }
              { (newContactModal) &&
                <div className='split-inputs'>
                  <IonItem>
                    <IonIcon icon={person} slot="start" />
                    <IonInput
                      placeholder="First Name"
                      id="contacts-name-input"
                      value={newContactModal?.firstName}
                      disabled={!(newContactModal && !newContactModal.userId)}
                      onIonInput={(e) => { 
                        const firstName = Utilities.capitalizeEachFirstLetter((e.target as HTMLIonInputElement).value.toString());
                        setNewContactModal({
                          ...newContactModal,
                          firstName: firstName
                        })

                        if (newContactModal.organisationId) {
                          searchContactsInOrg(newContactModal.organisationId, firstName, newContactModal.lastName);
                        }
                      }}
                    />
                  </IonItem>
                  <IonItem>
                    <IonInput
                      placeholder="Last Name"
                      id="contacts-last-name-input"
                      value={newContactModal?.lastName}
                      disabled={!(newContactModal && !newContactModal.userId)}
                      onIonInput={(e) => { 
                        const lastName = Utilities.capitalizeEachFirstLetter((e.target as HTMLIonInputElement).value.toString());
                        setNewContactModal({
                          ...newContactModal,
                          lastName: lastName
                        })
                      }}
                    />
                  </IonItem>
                </div>
              }
              { (orgContactSuggestions && newContactModal && !newContactModal.userId && orgContactSuggestions.length) ?
                <div className='contact-suggestions'>
                  {/* todo reset ifclosed */}
                  <h3>Contacts in {newContactModal.organisationName}</h3>
                  { orgContactSuggestions.map((contact, i) => {
                    return (
                      <IonItem
                        key={contact.contactId}
                        style={{
                          '--background': 'transparent',
                          '--border-color': "transparent",
                        }}
                        onClick={() => {
                          setNewContactModal({
                            ...newContactModal,
                            contactName: contact.firstName + " " + contact.lastName,
                            firstName: contact.firstName,
                            lastName: contact.lastName,
                            userId: contact.id,
                            contactId: contact.contactId
                            // forceShareOnly: !newContactModal.isAgency,
                          })
                          setOrgContactSuggestions([]);
                        }}
                      >
                        <IonImg style={{
                          width: 32,
                          height: 32,
                          borderRadius: '100%',
                          overflow: 'hidden'
                        }} src={UserAPI.getProfilePicture(contact.id)} slot="start" />
                        <IonLabel>{contact.firstName} {contact.lastName}</IonLabel>
                        <IonIcon icon={addCircle} slot="end" />
                      </IonItem>
                    )
                  }) }
                </div> : null
              }
              { (newContactModal && !newContactModal.userId) &&
                <IonItem>
                  <IonIcon icon={mail} slot="start" />
                  <IonInput
                    placeholder="Contact's Email"
                    id="contacts-email-input"
                    value={newContactModal?.contactEmail}
                    onIonInput={(e) => {
                      setNewContactModal({
                        ...newContactModal,
                        contactEmail: ((e.target as HTMLIonInputElement).value.toString()).trim()
                      })
                    }}
                  />
                </IonItem>
              }
              { (newContactModal && !newContactModal.forceShareOnly) &&
                <IonItem style={{
                  marginTop: 5,
                  "--border-color": "transparent"
                }}>
                  <IonCheckbox 
                    slot="start"
                    style={{
                      '--border-radius': '6px',
                    }}
                    checked={newContactModal.type === "agency" || newContactModal.type === "both"}
                    onIonChange={(e) => {
                      setNewContactModal({
                        ...newContactModal,
                        type: e.detail.checked ? "both" : "hirer"
                      })
                    }}
                  />
                  This contact is an agency who represents me
                </IonItem>
              }
            </IonList>
            { (newContactModal && newContactModal.type === "both") &&
              <div className='sensitive-information-share'>
                {/* <IonIcon icon={documentLock} />
                <span>To be verified, your partial email & mobile e.g. “email: J***.***gs@h******.co**”, mobile# 07*****29***93 will be briefly shared with this contact requesting verification. They will not see your full details. ”</span> */}
                <span>We will ask the agency to verify your identity and representation</span>
              </div>
            }
            { (newContactModal) &&
              <button
                className='main'
                style={{
                  margin: '20px 15px 0 15px',
                  whiteSpace: 'initial'
                }}
                onClick={() => {
                  sendInviteV2(newContactModal)
                }}
              >
                { newContactModal ? (newContactModal.type === "both" ? "Start Sharing and Request Representation" : "Start Sharing") : "" }
              </button>
            }
          </div>
        </IonContent>
      </IonModal>      
      <IonModal
        isOpen={inviteMode !== null}
        canDismiss={inviteMode === null}
        presentingElement={(contentRef && contentRef.current) ? contentRef.current : null}
        onDidDismiss={() => { 
          setInviteMode(null) 
          setInviteModalEmail("")
          setInviteModalName("")
        }}
        data-modal="more-invite-info"
        mode="ios"
      >
        <IonHeader mode="md">
          <IonToolbar>
            <IonTitle>Add your contacts details</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setInviteMode(null)
                setInviteModalEmail("")
                setInviteModalName("")
              }}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            { (inviteParams?.orgId) && 
              <ContactListItem
                key={inviteParams.orgId + searchText}
                contact={{ companyId: inviteParams.orgId, companyName: inviteParams.orgName }}
                list="EMPLOYER"
                first={true}
                onlyOrgHeader={true}
              />
            }
            <IonItem>
              <IonLabel position="stacked">Your Contact's Full Name</IonLabel>
              <IonInput
                placeholder="Full Name" 
                id="name"
                value={inviteModalName} 
                onIonInput={(e) => { 
                  setInviteModalName((e.target as HTMLIonInputElement).value.toString());
                }}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Your Contact's Email</IonLabel>
              <IonInput
                placeholder="Email" 
                id="invite-moda-email-input"
                value={inviteModalEmail} 
                onIonInput={(e) => { 
                  setInviteModalEmail((e.target as HTMLIonInputElement).value.toString());
                }}
                onFocus={() => {
                  if (inviteModalEmailFocusAtBeginning) {
                    setInviteModalEmailFocusAtBeginning(false);
                    var inp = document.getElementById("invite-moda-email-input").firstElementChild;
                    // @ts-ignore
                    if (inp && inp.createTextRange) {
                      // @ts-ignore
                        var part = inp.createTextRange();
                        part.move("character", 0);
                        part.select();
                        // @ts-ignore
                    } else if (inp && inp.setSelectionRange) {
                      // @ts-ignore
                        inp.setSelectionRange(0, 0);
                    }
                  }
                }}
              />
            </IonItem>
            <IonButton
              style={{
                margin: '20px 15px 0 15px',
              }}
              expand="block"
              onClick={() => {

                sendExtendedInvite({
                  ...inviteParams,
                  email: inviteModalEmail.trim() || "",
                  name: inviteModalName.trim() || "",
                }, inviteMode)

              }}
            >Sent Invites</IonButton>
          </IonList>
        </IonContent>
      </IonModal>
      { (reportConfirmContactId !== null) &&
        <ReportModal
          type={ReportTypeId.Contact}
          id={reportConfirmContactId}
          open={true}
          onClose={() => {
            setReportConfirmContactId(null)
          }}
        />
      }
      { (rateModal) &&
        <RatingModal
          onClose={(stars, publicComment) => {
            
            let ratedEntity: null | "contact" | "organisation";
            let ratedName: null | string = null;
            let ratedContacts = [];

            if (rateModal.userId) {
              ratedContacts = [rateModal.uContactId];
            } else if (rateModal.organisationId) {
              ratedContacts = hirers.concat(agencies, independent).filter(hirer => hirer.organisationId === rateModal.organisationId).map(hirer => hirer.contacts.map(contact => contact.contactId)).flat();
            }

            if (stars === 1) {
              setConfirmContactRemove(ratedContacts);
            }

            if (stars) {
              for (let i = 0; i < hirers.length; i++) {
                if (hirers[i].organisationId === rateModal.organisationId) {
                  hirers[i].rating = { ...getDummyRatingRating(), stars: stars }; ratedName = hirers[i].organisationName; ratedEntity = "organisation";
                }
                for (let j = 0; j < hirers[i].contacts.length; j++) {
                  if (rateModal.userId && hirers[i].contacts[j].userId === rateModal.userId) {
                    hirers[i].contacts[j].rating = { ...getDummyRatingRating(), stars: stars }; ratedName = hirers[i].contacts[j].firstName + " " + hirers[i].contacts[j].lastName; ratedEntity = "contact";
                  }
                }
              }
              setHirers(hirers);
              for (let i = 0; i < agencies.length; i++) {
                if (agencies[i].organisationId === rateModal.organisationId) {
                  agencies[i].rating = { ...getDummyRatingRating(), stars: stars };  ratedName = agencies[i].organisationName; ratedEntity = "organisation";
                }
                for (let j = 0; j < agencies[i].contacts.length; j++) {
                  if (rateModal.userId && agencies[i].contacts[j].userId === rateModal.userId) {
                    agencies[i].contacts[j].rating = { ...getDummyRatingRating(), stars: stars }; ratedName = agencies[i].contacts[j].firstName + " " + agencies[i].contacts[j].lastName; ratedEntity = "contact";
                  }
                }
              }
              setAgencies(agencies)
              for (let i = 0; i < independent.length; i++) {
                for (let j = 0; j < independent[i].contacts.length; j++) {
                  if (rateModal.userId && independent[i].contacts[j].userId === rateModal.userId) {
                    independent[i].contacts[j].rating = { ...getDummyRatingRating(), stars: stars }; ratedName = independent[i].contacts[j].firstName + " " + independent[i].contacts[j].lastName; ratedEntity = "contact";
                  }
                }
              }
              setIndependent(independent)
              if (outgoings) {
                for (let i = 0; i < outgoings.length; i++) {
                  for (let j = 0; j < outgoings[i].contacts.length; j++) {
                    if (rateModal.userId && outgoings[i].contacts[j].userId === rateModal.userId) {
                      outgoings[i].contacts[j].rating = { ...getDummyRatingRating(), stars: stars }; ratedName = outgoings[i].contacts[j].firstName; ratedEntity = "contact";
                    }
                  }
                }
              }
              setOutgoings(outgoings);

              if ((window as any).os !== "web" && ratedName && (publicComment || stars)) {
                let text = 
                  (publicComment && stars) ? 
                    "I rated " + ratedName + " " + stars + " stars on Updatedge: " + publicComment :
                    (publicComment) ?
                      "I rated " + ratedName + " on Updatedge: " + publicComment :
                      "I rated " + ratedName + " " + stars + " stars on Updatedge";
                Share.share({
                  title: text,
                  text: text,
                  url: "https://updatedge.com",
                  dialogTitle: text
                }).catch(e => {});
              }
            }
            reloadContacts();
            setRateModal(null);
            // setNonBlockingLoading(null);
          }}
          rateeName={rateModal ? (rateModal.organisationName || rateModal.userName) : ""}
          recipient={{
            userId: rateModal.userId,
            contactId: rateModal.contactId,
            organisationId: rateModal.organisationId,
            organisationType: rateModal.organisationType,
            organisationName: rateModal.organisationName,
            organisationNameJustStr: rateModal.organisationNameJustStr,
          }}
        />
      }
      <IonLoading
        isOpen={blockingLoading != null}
        onDidDismiss={() => setBlockingLoading(null)}
        message={blockingLoading}
      />
      </IonContent>
    </IonPage>
  );
};

export default Contacts;